
import React, { useState, useEffect } from 'react';
import { API } from '@aws-amplify/api';
import { listAsanaProjects } from '../graphql/queries';
import '../styles/Tasks.css';
import MainContent from '../containers/MainContent';
import { mdiLoading, mdiAws, mdiSourceBranchSync, mdiGoogleCloud } from '@mdi/js';
import { Icon } from '@mdi/react';
import Task from '../components/Task';
import { SiAlteryx, SiVmware } from "react-icons/si";
import ProjectHeader from '../components/ProjectHeader';
function Tasks() {
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 20;
  const [loading, setLoading] = useState(true);
  const [offsetToken, setOffsetToken] = useState(null);
  const [selectedProject, setSelectedProject] = useState('OLA');
  const [previousSelectedProject, setPreviousSelectedProject] = useState('');



  useEffect(() => {
    fetchTasks();
  }, [selectedProject]);

  const onProjectSelect = (selectedProject) => {
    setPreviousSelectedProject(selectedProject !== previousSelectedProject ? previousSelectedProject : 'OLA');
    setSelectedProject(selectedProject);
};
  async function fetchTasks() {
    setLoading(true);
    try {
      const apiData = await API.graphql({
        query: listAsanaProjects,
        variables: { input: { project: selectedProject, offset: "", limit: 100 } },
      });
      const tasksResponse = apiData.data.listAsanaProjects.response;
      if (tasksResponse) {
        const tasksData = JSON.parse(tasksResponse);
        console.log(tasksData.data);
        setTasks(tasksData.data || []);
        setOffsetToken(tasksData.next_page ? tasksData.next_page.offset : null);
      }
    } catch (err) {
      console.error('Fetch failed:', err);
    }
    setLoading(false);
  }

  const indexOfLastTask = currentPage * tasksPerPage;
  const indexOfFirstTask = indexOfLastTask - tasksPerPage;
  const currentTasks = tasks.slice(indexOfFirstTask, indexOfLastTask);



  const projectsConfig = {
    'OLA': {
      title: 'OLA',
      icon: mdiAws,
      iconStyle: 'ola-project-icon',
      style: {},
      selectedStyle: {
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #FF9900 14.3%, #FF9900 28.6%, #252F3E 28.6%, #2d3a4d 42.9%, #FF9900 42.9%, #ffc446 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #FF9900 85.8%, #FF9900 100%)'
      },
      notSelectedStyle: {
        backgroundSize: '600% 100%',
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #FF9900 14.3%, #FF9900 28.6%, #252F3E 28.6%, #2d3a4d 42.9%, #FF9900 42.9%, #ffc446 57.2%, #2a2a2a 57.2%, #1b1b1b 71.8%, #252F3E 71.8%, #2d3a4d 85.8%, #FF9900 85.8%, #FF9900 100%)'
      }
    },
    'GCP': {
      title: 'Google Tasks',
      icon: mdiGoogleCloud,
      style: {},
      iconStyle: 'gcp-project-icon',
      selectedStyle: {
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #3f77d1 14.3% ,#4285F4 28.6%, #c63f32 28.6%, #DB4437 42.9%, #d69d00 42.9%, #F4B400 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #2b8a44 85.8%, #34A853 100%)'
      },
      notSelectedStyle: {
        backgroundSize: '600% 100%',
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #3f77d1 14.3% ,#4285F4 28.6%, #c63f32 28.6%, #DB4437 42.9%, #d69d00 42.9%, #F4B400 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #2b8a44 85.8%, #34A853 100%)'
      }
    },
    'VMWare': {
      title: 'VMWare Tasks',
      icon: <SiVmware />,
      iconStyle: 'vmware-project-icon',
      style: {},
      selectedStyle: {
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #4ca4a8 14.3%, #5cc4ca 28.6%, #dadada 28.6%, #fcfcfc 42.9%, #3e8aba 42.9%, #479fd4 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #4ca4a8 85.8%, #5cc4ca 100%)'
      },
      notSelectedStyle: {
        backgroundSize: '600% 100%',
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #4ca4a8 14.3%, #5cc4ca 28.6%, #dadada 28.6%, #fcfcfc 42.9%, #3e8aba 42.9%, #479fd4 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #4ca4a8 85.8%, #5cc4ca 100%)'
    }
    },
    'ProServe': {
      title: 'ProServe',
      icon: mdiAws,
      iconStyle: 'proserve-project-icon',
      style: {},
      selectedStyle: {
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #FF9900 14.3%, #FF9900 28.6%, #252F3E 28.6%, #2d3a4d 42.9%, #FF9900 42.9%, #ffc446 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #FF9900 85.8%, #FF9900 100%)'
      },
      notSelectedStyle: {
        backgroundSize: '600% 100%',
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #FF9900 14.3%, #FF9900 28.6%, #252F3E 28.6%, #2d3a4d 42.9%, #FF9900 42.9%, #ffc446 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #FF9900 85.8%, #FF9900 100%)'
      }
    },
    'ALTERYX': {
      title: 'Alteryx',
      icon: <SiAlteryx />,
      iconStyle: 'alteryx-project-icon',
      style: {},
      selectedStyle: {
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #0e1e36 14.3%, #08162B 28.6%, #252F3E 28.6%, #2d3a4d 42.9%, #FFDCDB 42.9%, #fccbca 57.2%,#2a2a2a 57.2%, #1b1b1b 85.8%, #0071b0 85.8%, #0082CA 100%)'
      },
      notSelectedStyle: {
        backgroundSize: '600% 100%',
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #0e1e36 14.3%, #08162B 28.6%, #252F3E 28.6%, #2d3a4d 42.9%, #FFDCDB 42.9%, #fccbca 57.2%,#2a2a2a 57.2%, #1b1b1b 85.8%, #0071b0 85.8%, #0082CA 100%)'
      }
    },
    'Evolve AI': {
      title: 'EvolveAI',
      icon: mdiSourceBranchSync,
      iconStyle: 'evolveai-project-icon',
      style: {},
      selectedStyle: {
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #6c6c6c 14.3%, #585858 28.6%, rgb(144, 144, 144) 28.6%, #c7c7c7 42.9%, #fe9c02 42.9%, #fe9c02 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #ffc446 85.8%, #fe9c02 100%)'
      },
      notSelectedStyle: {
        backgroundSize: '600% 100%',
        background: 'linear-gradient(90deg, #2a2a2a 0%, #1b1b1b 14.3%, #6c6c6c 14.3%, #585858 28.6%, rgb(144, 144, 144) 28.6%, #c7c7c7 42.9%, #fe9c02 42.9%, #fe9c02 57.2%, #2a2a2a 57.2%, #1b1b1b 85.8%, #ffc446 85.8%, #fe9c02 100%)'
      }
    }

  };
  

// Dynamically select project config
  //const projectConfig = projectsConfig[selectedProject] || {};


  return (
    <MainContent>
      <ProjectHeader
          projectsConfig={projectsConfig}
          onProjectSelect={onProjectSelect}
      />
        <div className="tasks-list">
          {loading ? (
            <Icon path={mdiLoading} size={2} className="loading-icon" />
          ) : (
            currentTasks.map((task) => (
              <Task key={task.gid} task={task} />
            ))
          )}
        </div>
        <div className="pagination">
          <button disabled={currentPage <= 1} onClick={() => setCurrentPage((prev) => prev - 1)}> Prev </button>
          <button disabled={currentTasks.length < tasksPerPage} onClick={() => setCurrentPage((prev) => prev + 1)}> Next </button>
        </div>
    </MainContent>
  );
}

export default Tasks;