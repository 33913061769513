import React, { useState } from 'react';
import FileUpload from '../components/FileUpload';
import MainContent from '../containers/MainContent';

const MLSReconcile = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFileUpload = (file) => {
    console.log('File Uploaded:', file.name);
    setIsSubmitted(true);
    // Here you can add the logic to handle the file upload
  };

return (
    <MainContent>
      <div className='bar-header'>
        <div className='logo-header'>
          <img src="/evolve_MLS.svg" alt="MLS Reconciliation" />
        </div>
        <div className='content'>
          <FileUpload onFileUpload={handleFileUpload} isSubmitted={isSubmitted} setIsSubmitted={setIsSubmitted} />
        </div>
      </div>
    </MainContent>
  );
};

export default MLSReconcile;
