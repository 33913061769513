import React from 'react';
import '../styles/SectionContainer.css'

const SectionContainer = ({ children }) => {

    return (
        <div className="section-container">
            {children}
        </div>
    );
};

export default SectionContainer;
