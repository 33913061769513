import React, { useState } from 'react';
import '../styles/FileUpload.css'; // Ensure this path is correct

const FileUpload = ({ onFileUpload, isSubmitted, setIsSubmitted }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (file) {
      onFileUpload(file);
      setFile(null);
      setIsSubmitted(true);
    }
  };

  return (
    <div className={`file-upload ${isSubmitted ? 'submitted' : ''} ${file ? 'has-file' : ''}`}>
      <label htmlFor="file-input" className={`file-label ${file ? 'has-file' : ''}`}>
        Choose a file...
      </label>
      <div className="file-name">
        {file ? file.name : 'No file uploaded'}
      </div>
      <button 
        onClick={handleSubmit} 
        className={`upload-button ${file ? 'has-file' : ''}`} 
        disabled={!file}>
        Upload
      </button>
      <input
        type="file"
        id="file-input"
        onChange={handleFileChange}
        className="file-input"
      />
    </div>
  );
};

export default FileUpload;
