import React, { createContext, useContext, useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

const UserGroupContext = createContext();

export const UserGroupProvider = ({ children }) => {
    const [userGroup, setUserGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        checkUserGroup();
    }, []);

    const checkUserGroup = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
            console.log("User Group: ", groups)
            setUserGroup(groups[0] || 'Default'); // Set the first group as the user group, or 'Default' if no groups
        } catch (error) {
            console.error('Error fetching user group:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <UserGroupContext.Provider value={{ userGroup, isLoading }}>
            {children}
        </UserGroupContext.Provider>
    );
};

export const useUserGroup = () => useContext(UserGroupContext);
