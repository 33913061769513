/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSessionId = /* GraphQL */ `
  query CreateSessionId($input: createSessionIdInput!) {
    createSessionId(input: $input) {
      session
      __typename
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      title
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTasks = /* GraphQL */ `
  query SyncTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTasks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPrivateNote = /* GraphQL */ `
  query GetPrivateNote($id: ID!) {
    getPrivateNote(id: $id) {
      id
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listPrivateNotes = /* GraphQL */ `
  query ListPrivateNotes(
    $filter: ModelPrivateNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPrivateNotes = /* GraphQL */ `
  query SyncPrivateNotes(
    $filter: ModelPrivateNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPrivateNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getKendraFaq = /* GraphQL */ `
  query GetKendraFaq($id: ID!) {
    getKendraFaq(id: $id) {
      id
      question
      answer
      FAQName
      previousQ
      previousA
      lastUpdatedBy
      lastUpdatedAt
      createdBy
      createdAt
      s3Path
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listKendraFaqs = /* GraphQL */ `
  query ListKendraFaqs(
    $filter: ModelKendraFaqFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKendraFaqs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        question
        answer
        FAQName
        previousQ
        previousA
        lastUpdatedBy
        lastUpdatedAt
        createdBy
        createdAt
        s3Path
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncKendraFaqs = /* GraphQL */ `
  query SyncKendraFaqs(
    $filter: ModelKendraFaqFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncKendraFaqs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        question
        answer
        FAQName
        previousQ
        previousA
        lastUpdatedBy
        lastUpdatedAt
        createdBy
        createdAt
        s3Path
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatHistoryMessage = /* GraphQL */ `
  query GetChatHistoryMessage($id: ID!) {
    getChatHistoryMessage(id: $id) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatHistoryMessages = /* GraphQL */ `
  query ListChatHistoryMessages(
    $filter: ModelChatHistoryMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatHistoryMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        content
        sender
        context
        type
        backend
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatHistoryMessages = /* GraphQL */ `
  query SyncChatHistoryMessages(
    $filter: ModelChatHistoryMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatHistoryMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        content
        sender
        context
        type
        backend
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatHistorySession = /* GraphQL */ `
  query GetChatHistorySession($id: ID!) {
    getChatHistorySession(id: $id) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatHistorySessions = /* GraphQL */ `
  query ListChatHistorySessions(
    $filter: ModelChatHistorySessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatHistorySessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatHistorySessions = /* GraphQL */ `
  query SyncChatHistorySessions(
    $filter: ModelChatHistorySessionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatHistorySessions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatMessageHistory = /* GraphQL */ `
  query GetChatMessageHistory($session: ID!, $messageID: Int!) {
    getChatMessageHistory(session: $session, messageID: $messageID) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      uiHistory {
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatMessageHistories = /* GraphQL */ `
  query ListChatMessageHistories(
    $session: ID
    $messageID: ModelIntKeyConditionInput
    $filter: ModelChatMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listChatMessageHistories(
      session: $session
      messageID: $messageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        content
        sender
        context
        type
        timestamp
        messageID
        session
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatMessageHistories = /* GraphQL */ `
  query SyncChatMessageHistories(
    $filter: ModelChatMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatMessageHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        owner
        content
        sender
        context
        type
        timestamp
        messageID
        session
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChatSessionHistory = /* GraphQL */ `
  query GetChatSessionHistory($id: ID!) {
    getChatSessionHistory(id: $id) {
      id
      owner
      topic
      emoji
      session
      timestamp
      redshiftSuperTables
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChatSessionHistories = /* GraphQL */ `
  query ListChatSessionHistories(
    $filter: ModelChatSessionHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatSessionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        topic
        emoji
        session
        timestamp
        redshiftSuperTables
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChatSessionHistories = /* GraphQL */ `
  query SyncChatSessionHistories(
    $filter: ModelChatSessionHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChatSessionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        topic
        emoji
        session
        timestamp
        redshiftSuperTables
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getNotes = /* GraphQL */ `
  query GetNotes($session: ID!, $userId: String!, $messageID: Int!) {
    getNotes(session: $session, userId: $userId, messageID: $messageID) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $session: ID
    $userIdMessageID: ModelNotesPrimaryCompositeKeyConditionInput
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotes(
      session: $session
      userIdMessageID: $userIdMessageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncNotes = /* GraphQL */ `
  query SyncNotes(
    $filter: ModelNotesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        messageID
        note
        model
        thought
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContext = /* GraphQL */ `
  query GetContext($session: ID!, $userId: String!, $messageID: Int!) {
    getContext(session: $session, userId: $userId, messageID: $messageID) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContexts = /* GraphQL */ `
  query ListContexts(
    $session: ID
    $userIdMessageID: ModelContextPrimaryCompositeKeyConditionInput
    $filter: ModelContextFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listContexts(
      session: $session
      userIdMessageID: $userIdMessageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContexts = /* GraphQL */ `
  query SyncContexts(
    $filter: ModelContextFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContexts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        name
        relation
        context
        timestamp
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInstruct = /* GraphQL */ `
  query GetInstruct($session: ID!, $userId: String!, $messageID: Int!) {
    getInstruct(session: $session, userId: $userId, messageID: $messageID) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInstructs = /* GraphQL */ `
  query ListInstructs(
    $session: ID
    $userIdMessageID: ModelInstructPrimaryCompositeKeyConditionInput
    $filter: ModelInstructFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInstructs(
      session: $session
      userIdMessageID: $userIdMessageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInstructs = /* GraphQL */ `
  query SyncInstructs(
    $filter: ModelInstructFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstructs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        session
        instruction
        reason
        directTo
        messageID
        userId
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAWSServices = /* GraphQL */ `
  query GetAWSServices($id: ID!) {
    getAWSServices(id: $id) {
      id
      dateOfLastChange
      numberOfChanges
      changes
      currentVersion
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAWSServices = /* GraphQL */ `
  query ListAWSServices(
    $filter: ModelAWSServicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAWSServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateOfLastChange
        numberOfChanges
        changes
        currentVersion
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAWSServices = /* GraphQL */ `
  query SyncAWSServices(
    $filter: ModelAWSServicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAWSServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        dateOfLastChange
        numberOfChanges
        changes
        currentVersion
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getServiceVersions = /* GraphQL */ `
  query GetServiceVersions($service: ID!, $version: String!) {
    getServiceVersions(service: $service, version: $version) {
      service
      version
      url
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listServiceVersions = /* GraphQL */ `
  query ListServiceVersions(
    $service: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelServiceVersionsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listServiceVersions(
      service: $service
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        service
        version
        url
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncServiceVersions = /* GraphQL */ `
  query SyncServiceVersions(
    $filter: ModelServiceVersionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServiceVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        service
        version
        url
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEC2Pricing = /* GraphQL */ `
  query GetEC2Pricing($id: ID!, $region: String!) {
    getEC2Pricing(id: $id, region: $region) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEC2Pricings = /* GraphQL */ `
  query ListEC2Pricings(
    $id: ID
    $region: ModelStringKeyConditionInput
    $filter: ModelEC2PricingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEC2Pricings(
      id: $id
      region: $region
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEC2Pricings = /* GraphQL */ `
  query SyncEC2Pricings(
    $filter: ModelEC2PricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEC2Pricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ec2PricingByRegionAndInstanceType = /* GraphQL */ `
  query Ec2PricingByRegionAndInstanceType(
    $instanceType: String!
    $region: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEC2PricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ec2PricingByRegionAndInstanceType(
      instanceType: $instanceType
      region: $region
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ec2PricingByCost = /* GraphQL */ `
  query Ec2PricingByCost(
    $cost: Float!
    $region: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEC2PricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ec2PricingByCost(
      cost: $cost
      region: $region
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDedicatedHostPricing = /* GraphQL */ `
  query GetDedicatedHostPricing($id: ID!, $region: String!) {
    getDedicatedHostPricing(id: $id, region: $region) {
      id
      instanceType
      region
      cores
      ram
      iops
      throughput
      networkSpeed
      clockSpeed
      cost
      onDemandCost
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDedicatedHostPricings = /* GraphQL */ `
  query ListDedicatedHostPricings(
    $id: ID
    $region: ModelStringKeyConditionInput
    $filter: ModelDedicatedHostPricingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDedicatedHostPricings(
      id: $id
      region: $region
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        throughput
        networkSpeed
        clockSpeed
        cost
        onDemandCost
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDedicatedHostPricings = /* GraphQL */ `
  query SyncDedicatedHostPricings(
    $filter: ModelDedicatedHostPricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDedicatedHostPricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        throughput
        networkSpeed
        clockSpeed
        cost
        onDemandCost
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dedicatedHostPricingByRegionAndInstanceType = /* GraphQL */ `
  query DedicatedHostPricingByRegionAndInstanceType(
    $instanceType: String!
    $region: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDedicatedHostPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dedicatedHostPricingByRegionAndInstanceType(
      instanceType: $instanceType
      region: $region
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        throughput
        networkSpeed
        clockSpeed
        cost
        onDemandCost
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dedicatedHostPricingByCost = /* GraphQL */ `
  query DedicatedHostPricingByCost(
    $cost: Float!
    $region: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDedicatedHostPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dedicatedHostPricingByCost(
      cost: $cost
      region: $region
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        throughput
        networkSpeed
        clockSpeed
        cost
        onDemandCost
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReservedInstancePricing = /* GraphQL */ `
  query GetReservedInstancePricing($id: ID!, $instanceType: String!) {
    getReservedInstancePricing(id: $id, instanceType: $instanceType) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      term
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReservedInstancePricings = /* GraphQL */ `
  query ListReservedInstancePricings(
    $id: ID
    $instanceType: ModelStringKeyConditionInput
    $filter: ModelReservedInstancePricingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReservedInstancePricings(
      id: $id
      instanceType: $instanceType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        term
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReservedInstancePricings = /* GraphQL */ `
  query SyncReservedInstancePricings(
    $filter: ModelReservedInstancePricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservedInstancePricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        term
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reservedInstancePricingByRegionAndTerm = /* GraphQL */ `
  query ReservedInstancePricingByRegionAndTerm(
    $region: String!
    $term: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservedInstancePricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservedInstancePricingByRegionAndTerm(
      region: $region
      term: $term
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        term
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reservedInstancePricingByCost = /* GraphQL */ `
  query ReservedInstancePricingByCost(
    $cost: Float!
    $instanceType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservedInstancePricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservedInstancePricingByCost(
      cost: $cost
      instanceType: $instanceType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        term
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reservedInstancePricingByInstanceTypeAndTerm = /* GraphQL */ `
  query ReservedInstancePricingByInstanceTypeAndTerm(
    $term: String!
    $instanceType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservedInstancePricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reservedInstancePricingByInstanceTypeAndTerm(
      term: $term
      instanceType: $instanceType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        instanceType
        region
        cores
        ram
        iops
        os
        databaseEdition
        databaseVersion
        throughput
        networkSpeed
        clockSpeed
        cost
        term
        upFrontCost
        hourlyCost
        unitOfCost
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEC2InstanceTypes = /* GraphQL */ `
  query GetEC2InstanceTypes($instanceType: String!, $vcpus: Int!) {
    getEC2InstanceTypes(instanceType: $instanceType, vcpus: $vcpus) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEC2InstanceTypes = /* GraphQL */ `
  query ListEC2InstanceTypes(
    $instanceType: String
    $vcpus: ModelIntKeyConditionInput
    $filter: ModelEC2InstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEC2InstanceTypes(
      instanceType: $instanceType
      vcpus: $vcpus
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEC2InstanceTypes = /* GraphQL */ `
  query SyncEC2InstanceTypes(
    $filter: ModelEC2InstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEC2InstanceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRDSInstanceTypes = /* GraphQL */ `
  query GetRDSInstanceTypes($instanceType: String!, $vcpus: Int!) {
    getRDSInstanceTypes(instanceType: $instanceType, vcpus: $vcpus) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRDSInstanceTypes = /* GraphQL */ `
  query ListRDSInstanceTypes(
    $instanceType: String
    $vcpus: ModelIntKeyConditionInput
    $filter: ModelRDSInstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRDSInstanceTypes(
      instanceType: $instanceType
      vcpus: $vcpus
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRDSInstanceTypes = /* GraphQL */ `
  query SyncRDSInstanceTypes(
    $filter: ModelRDSInstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRDSInstanceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAzureInstanceTypes = /* GraphQL */ `
  query GetAzureInstanceTypes($instanceType: String!, $vcpus: Int!) {
    getAzureInstanceTypes(instanceType: $instanceType, vcpus: $vcpus) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAzureInstanceTypes = /* GraphQL */ `
  query ListAzureInstanceTypes(
    $instanceType: String
    $vcpus: ModelIntKeyConditionInput
    $filter: ModelAzureInstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAzureInstanceTypes(
      instanceType: $instanceType
      vcpus: $vcpus
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAzureInstanceTypes = /* GraphQL */ `
  query SyncAzureInstanceTypes(
    $filter: ModelAzureInstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAzureInstanceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGCPInstanceTypes = /* GraphQL */ `
  query GetGCPInstanceTypes($instanceType: String!, $vcpus: Int!) {
    getGCPInstanceTypes(instanceType: $instanceType, vcpus: $vcpus) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGCPInstanceTypes = /* GraphQL */ `
  query ListGCPInstanceTypes(
    $instanceType: String
    $vcpus: ModelIntKeyConditionInput
    $filter: ModelGCPInstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGCPInstanceTypes(
      instanceType: $instanceType
      vcpus: $vcpus
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGCPInstanceTypes = /* GraphQL */ `
  query SyncGCPInstanceTypes(
    $filter: ModelGCPInstanceTypesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGCPInstanceTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        instanceType
        currentGeneration
        vcpus
        ram
        peakNetworkMBps
        peakThroughputMBps
        peakIops
        baselineNetworkMBps
        baselineThroughputMBps
        baselineIops
        clockSpeed
        proccessorArchitecture
        processor
        avgHourlyCost
        gpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEC2PricingVersion = /* GraphQL */ `
  query GetEC2PricingVersion($id: ID!) {
    getEC2PricingVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEC2PricingVersions = /* GraphQL */ `
  query ListEC2PricingVersions(
    $filter: ModelEC2PricingVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEC2PricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEC2PricingVersions = /* GraphQL */ `
  query SyncEC2PricingVersions(
    $filter: ModelEC2PricingVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEC2PricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEC2SavingsPlanVersion = /* GraphQL */ `
  query GetEC2SavingsPlanVersion($id: ID!) {
    getEC2SavingsPlanVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEC2SavingsPlanVersions = /* GraphQL */ `
  query ListEC2SavingsPlanVersions(
    $filter: ModelEC2SavingsPlanVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEC2SavingsPlanVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEC2SavingsPlanVersions = /* GraphQL */ `
  query SyncEC2SavingsPlanVersions(
    $filter: ModelEC2SavingsPlanVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEC2SavingsPlanVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRDSSavingsPlanVersion = /* GraphQL */ `
  query GetRDSSavingsPlanVersion($id: ID!) {
    getRDSSavingsPlanVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRDSSavingsPlanVersions = /* GraphQL */ `
  query ListRDSSavingsPlanVersions(
    $filter: ModelRDSSavingsPlanVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRDSSavingsPlanVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRDSSavingsPlanVersions = /* GraphQL */ `
  query SyncRDSSavingsPlanVersions(
    $filter: ModelRDSSavingsPlanVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRDSSavingsPlanVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRDSPricingVersion = /* GraphQL */ `
  query GetRDSPricingVersion($id: ID!) {
    getRDSPricingVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRDSPricingVersions = /* GraphQL */ `
  query ListRDSPricingVersions(
    $filter: ModelRDSPricingVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRDSPricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRDSPricingVersions = /* GraphQL */ `
  query SyncRDSPricingVersions(
    $filter: ModelRDSPricingVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRDSPricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReservedInstancePricingVersion = /* GraphQL */ `
  query GetReservedInstancePricingVersion($id: ID!) {
    getReservedInstancePricingVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReservedInstancePricingVersions = /* GraphQL */ `
  query ListReservedInstancePricingVersions(
    $filter: ModelReservedInstancePricingVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservedInstancePricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReservedInstancePricingVersions = /* GraphQL */ `
  query SyncReservedInstancePricingVersions(
    $filter: ModelReservedInstancePricingVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReservedInstancePricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDedicatedHostPricingVersion = /* GraphQL */ `
  query GetDedicatedHostPricingVersion($id: ID!) {
    getDedicatedHostPricingVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDedicatedHostPricingVersions = /* GraphQL */ `
  query ListDedicatedHostPricingVersions(
    $filter: ModelDedicatedHostPricingVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDedicatedHostPricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDedicatedHostPricingVersions = /* GraphQL */ `
  query SyncDedicatedHostPricingVersions(
    $filter: ModelDedicatedHostPricingVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDedicatedHostPricingVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSavingsPlanVersion = /* GraphQL */ `
  query GetSavingsPlanVersion($id: ID!) {
    getSavingsPlanVersion(id: $id) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSavingsPlanVersions = /* GraphQL */ `
  query ListSavingsPlanVersions(
    $filter: ModelSavingsPlanVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSavingsPlanVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSavingsPlanVersions = /* GraphQL */ `
  query SyncSavingsPlanVersions(
    $filter: ModelSavingsPlanVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSavingsPlanVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        region
        version
        date
        s3Path
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCaptureToolPathMap = /* GraphQL */ `
  query GetCaptureToolPathMap($id: ID!) {
    getCaptureToolPathMap(id: $id) {
      id
      jsonSchema
      Tool
      s3Path
      sheet
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCaptureToolPathMaps = /* GraphQL */ `
  query ListCaptureToolPathMaps(
    $filter: ModelCaptureToolPathMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCaptureToolPathMaps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jsonSchema
        Tool
        s3Path
        sheet
        ogJsonSchema
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCaptureToolPathMaps = /* GraphQL */ `
  query SyncCaptureToolPathMaps(
    $filter: ModelCaptureToolPathMapFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCaptureToolPathMaps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        jsonSchema
        Tool
        s3Path
        sheet
        ogJsonSchema
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getToolToSheetMap = /* GraphQL */ `
  query GetToolToSheetMap($id: ID!) {
    getToolToSheetMap(id: $id) {
      id
      Tool
      sheet
      jsonSchema
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listToolToSheetMaps = /* GraphQL */ `
  query ListToolToSheetMaps(
    $filter: ModelToolToSheetMapFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToolToSheetMaps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Tool
        sheet
        jsonSchema
        ogJsonSchema
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncToolToSheetMaps = /* GraphQL */ `
  query SyncToolToSheetMaps(
    $filter: ModelToolToSheetMapFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncToolToSheetMaps(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Tool
        sheet
        jsonSchema
        ogJsonSchema
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getChainOfCustody = /* GraphQL */ `
  query GetChainOfCustody($id: ID!) {
    getChainOfCustody(id: $id) {
      id
      taskId
      fileName
      timestamp
      arn
      user
      wereChangesMade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listChainOfCustodies = /* GraphQL */ `
  query ListChainOfCustodies(
    $filter: ModelChainOfCustodyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChainOfCustodies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        fileName
        timestamp
        arn
        user
        wereChangesMade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncChainOfCustodies = /* GraphQL */ `
  query SyncChainOfCustodies(
    $filter: ModelChainOfCustodyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChainOfCustodies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        taskId
        fileName
        timestamp
        arn
        user
        wereChangesMade
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOSLookup = /* GraphQL */ `
  query GetOSLookup($OLAToolOSValue: String!, $OSType: String!) {
    getOSLookup(OLAToolOSValue: $OLAToolOSValue, OSType: $OSType) {
      OLAToolOSValue
      OSType
      OSEdition
      OSVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOSLookups = /* GraphQL */ `
  query ListOSLookups(
    $OLAToolOSValue: String
    $OSType: ModelStringKeyConditionInput
    $filter: ModelOSLookupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOSLookups(
      OLAToolOSValue: $OLAToolOSValue
      OSType: $OSType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        OLAToolOSValue
        OSType
        OSEdition
        OSVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOSLookups = /* GraphQL */ `
  query SyncOSLookups(
    $filter: ModelOSLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOSLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        OLAToolOSValue
        OSType
        OSEdition
        OSVersion
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSQLVersionLookup = /* GraphQL */ `
  query GetSQLVersionLookup($id: ID!) {
    getSQLVersionLookup(id: $id) {
      id
      SQLServerVersion
      SQLV
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSQLVersionLookups = /* GraphQL */ `
  query ListSQLVersionLookups(
    $filter: ModelSQLVersionLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSQLVersionLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SQLServerVersion
        SQLV
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSQLVersionLookups = /* GraphQL */ `
  query SyncSQLVersionLookups(
    $filter: ModelSQLVersionLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSQLVersionLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SQLServerVersion
        SQLV
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDHPlacementLookup = /* GraphQL */ `
  query GetDHPlacementLookup($id: ID!) {
    getDHPlacementLookup(id: $id) {
      id
      vCpuCapacity
      RAMCapacity
      Heterogeneous
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDHPlacementLookups = /* GraphQL */ `
  query ListDHPlacementLookups(
    $filter: ModelDHPlacementLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDHPlacementLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vCpuCapacity
        RAMCapacity
        Heterogeneous
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDHPlacementLookups = /* GraphQL */ `
  query SyncDHPlacementLookups(
    $filter: ModelDHPlacementLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDHPlacementLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        vCpuCapacity
        RAMCapacity
        Heterogeneous
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMicrosoftLicenseLookup = /* GraphQL */ `
  query GetMicrosoftLicenseLookup($id: ID!) {
    getMicrosoftLicenseLookup(id: $id) {
      id
      EAorSCE
      Level
      Year1
      Year2
      Year3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMicrosoftLicenseLookups = /* GraphQL */ `
  query ListMicrosoftLicenseLookups(
    $filter: ModelMicrosoftLicenseLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMicrosoftLicenseLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        EAorSCE
        Level
        Year1
        Year2
        Year3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMicrosoftLicenseLookups = /* GraphQL */ `
  query SyncMicrosoftLicenseLookups(
    $filter: ModelMicrosoftLicenseLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMicrosoftLicenseLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        EAorSCE
        Level
        Year1
        Year2
        Year3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRegionCodeLookup = /* GraphQL */ `
  query GetRegionCodeLookup($id: ID!) {
    getRegionCodeLookup(id: $id) {
      id
      Region
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRegionCodeLookups = /* GraphQL */ `
  query ListRegionCodeLookups(
    $filter: ModelRegionCodeLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegionCodeLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Region
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRegionCodeLookups = /* GraphQL */ `
  query SyncRegionCodeLookups(
    $filter: ModelRegionCodeLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRegionCodeLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Region
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPricingModelLookup = /* GraphQL */ `
  query GetPricingModelLookup($id: ID!) {
    getPricingModelLookup(id: $id) {
      id
      Value
      Model
      Short
      DeviceChoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPricingModelLookups = /* GraphQL */ `
  query ListPricingModelLookups(
    $filter: ModelPricingModelLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPricingModelLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Value
        Model
        Short
        DeviceChoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPricingModelLookups = /* GraphQL */ `
  query SyncPricingModelLookups(
    $filter: ModelPricingModelLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPricingModelLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Value
        Model
        Short
        DeviceChoice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getHRGLookup = /* GraphQL */ `
  query GetHRGLookup($id: ID!) {
    getHRGLookup(id: $id) {
      id
      HRGList
      WSVersion
      WSEdition
      LicType
      LicAssignment
      DefaultLicType
      AutoCreatedHRG
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listHRGLookups = /* GraphQL */ `
  query ListHRGLookups(
    $filter: ModelHRGLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHRGLookups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        HRGList
        WSVersion
        WSEdition
        LicType
        LicAssignment
        DefaultLicType
        AutoCreatedHRG
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncHRGLookups = /* GraphQL */ `
  query SyncHRGLookups(
    $filter: ModelHRGLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncHRGLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        HRGList
        WSVersion
        WSEdition
        LicType
        LicAssignment
        DefaultLicType
        AutoCreatedHRG
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInstanceRatioLookup = /* GraphQL */ `
  query GetInstanceRatioLookup($id: ID!) {
    getInstanceRatioLookup(id: $id) {
      id
      InstanceFamily
      Ratio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInstanceRatioLookups = /* GraphQL */ `
  query ListInstanceRatioLookups(
    $filter: ModelInstanceRatioLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInstanceRatioLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        InstanceFamily
        Ratio
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInstanceRatioLookups = /* GraphQL */ `
  query SyncInstanceRatioLookups(
    $filter: ModelInstanceRatioLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInstanceRatioLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        InstanceFamily
        Ratio
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMicrosoftProductLookup = /* GraphQL */ `
  query GetMicrosoftProductLookup($id: ID!) {
    getMicrosoftProductLookup(id: $id) {
      id
      ProductName
      PricePerUnit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMicrosoftProductLookups = /* GraphQL */ `
  query ListMicrosoftProductLookups(
    $filter: ModelMicrosoftProductLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMicrosoftProductLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ProductName
        PricePerUnit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMicrosoftProductLookups = /* GraphQL */ `
  query SyncMicrosoftProductLookups(
    $filter: ModelMicrosoftProductLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMicrosoftProductLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ProductName
        PricePerUnit
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEnvLookup = /* GraphQL */ `
  query GetEnvLookup($Env: String!, $EnvType: String!) {
    getEnvLookup(Env: $Env, EnvType: $EnvType) {
      Env
      EnvType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEnvLookups = /* GraphQL */ `
  query ListEnvLookups(
    $Env: String
    $EnvType: ModelStringKeyConditionInput
    $filter: ModelEnvLookupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEnvLookups(
      Env: $Env
      EnvType: $EnvType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Env
        EnvType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEnvLookups = /* GraphQL */ `
  query SyncEnvLookups(
    $filter: ModelEnvLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEnvLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        Env
        EnvType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVMCOnTapLookup = /* GraphQL */ `
  query GetVMCOnTapLookup($id: ID!) {
    getVMCOnTapLookup(id: $id) {
      id
      IOPs
      throughputMBps
      storageMinGB
      storageMaxGB
      IOPsMax
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVMCOnTapLookups = /* GraphQL */ `
  query ListVMCOnTapLookups(
    $filter: ModelVMCOnTapLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMCOnTapLookups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        IOPs
        throughputMBps
        storageMinGB
        storageMaxGB
        IOPsMax
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVMCOnTapLookups = /* GraphQL */ `
  query SyncVMCOnTapLookups(
    $filter: ModelVMCOnTapLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVMCOnTapLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        IOPs
        throughputMBps
        storageMinGB
        storageMaxGB
        IOPsMax
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVMCModelLookup = /* GraphQL */ `
  query GetVMCModelLookup($id: ID!) {
    getVMCModelLookup(id: $id) {
      id
      Sizing
      Description
      Type
      Good
      Better
      Best
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVMCModelLookups = /* GraphQL */ `
  query ListVMCModelLookups(
    $filter: ModelVMCModelLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMCModelLookups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Sizing
        Description
        Type
        Good
        Better
        Best
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVMCModelLookups = /* GraphQL */ `
  query SyncVMCModelLookups(
    $filter: ModelVMCModelLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVMCModelLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Sizing
        Description
        Type
        Good
        Better
        Best
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getT3Lookup = /* GraphQL */ `
  query GetT3Lookup($id: ID!) {
    getT3Lookup(id: $id) {
      id
      instance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listT3Lookups = /* GraphQL */ `
  query ListT3Lookups(
    $filter: ModelT3LookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listT3Lookups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        instance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncT3Lookups = /* GraphQL */ `
  query SyncT3Lookups(
    $filter: ModelT3LookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncT3Lookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        instance
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSQLPricing = /* GraphQL */ `
  query GetSQLPricing($id: ID!) {
    getSQLPricing(id: $id) {
      id
      PricePervCpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSQLPricings = /* GraphQL */ `
  query ListSQLPricings(
    $filter: ModelSQLPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSQLPricings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        PricePervCpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSQLPricings = /* GraphQL */ `
  query SyncSQLPricings(
    $filter: ModelSQLPricingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSQLPricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        PricePervCpu
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVMCClusterLookup = /* GraphQL */ `
  query GetVMCClusterLookup($id: ID!) {
    getVMCClusterLookup(id: $id) {
      id
      Description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVMCClusterLookups = /* GraphQL */ `
  query ListVMCClusterLookups(
    $filter: ModelVMCClusterLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVMCClusterLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVMCClusterLookups = /* GraphQL */ `
  query SyncVMCClusterLookups(
    $filter: ModelVMCClusterLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVMCClusterLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWorkloadUtilLookup = /* GraphQL */ `
  query GetWorkloadUtilLookup($Workload: String!, $Metric: String!) {
    getWorkloadUtilLookup(Workload: $Workload, Metric: $Metric) {
      Metric
      Workload
      AvgUtil
      PeakUtil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWorkloadUtilLookups = /* GraphQL */ `
  query ListWorkloadUtilLookups(
    $Workload: String
    $Metric: ModelStringKeyConditionInput
    $filter: ModelWorkloadUtilLookupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkloadUtilLookups(
      Workload: $Workload
      Metric: $Metric
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        Metric
        Workload
        AvgUtil
        PeakUtil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWorkloadUtilLookups = /* GraphQL */ `
  query SyncWorkloadUtilLookups(
    $filter: ModelWorkloadUtilLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkloadUtilLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        Metric
        Workload
        AvgUtil
        PeakUtil
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWeightAdjustmentLookup = /* GraphQL */ `
  query GetWeightAdjustmentLookup($id: ID!) {
    getWeightAdjustmentLookup(id: $id) {
      Weight
      Adjustment
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWeightAdjustmentLookups = /* GraphQL */ `
  query ListWeightAdjustmentLookups(
    $filter: ModelWeightAdjustmentLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWeightAdjustmentLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Weight
        Adjustment
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWeightAdjustmentLookups = /* GraphQL */ `
  query SyncWeightAdjustmentLookups(
    $filter: ModelWeightAdjustmentLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWeightAdjustmentLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        Weight
        Adjustment
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWastedLicenseLookup = /* GraphQL */ `
  query GetWastedLicenseLookup($id: ID!) {
    getWastedLicenseLookup(id: $id) {
      Cores
      WastedLicenses
      AHUBLicenses
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWastedLicenseLookups = /* GraphQL */ `
  query ListWastedLicenseLookups(
    $filter: ModelWastedLicenseLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWastedLicenseLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        Cores
        WastedLicenses
        AHUBLicenses
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWastedLicenseLookups = /* GraphQL */ `
  query SyncWastedLicenseLookups(
    $filter: ModelWastedLicenseLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWastedLicenseLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        Cores
        WastedLicenses
        AHUBLicenses
        id
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEvolveAIPromptLookup = /* GraphQL */ `
  query GetEvolveAIPromptLookup($id: ID!) {
    getEvolveAIPromptLookup(id: $id) {
      id
      Prompt
      Response
      command
      reasoning
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEvolveAIPromptLookups = /* GraphQL */ `
  query ListEvolveAIPromptLookups(
    $filter: ModelEvolveAIPromptLookupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvolveAIPromptLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Prompt
        Response
        command
        reasoning
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEvolveAIPromptLookups = /* GraphQL */ `
  query SyncEvolveAIPromptLookups(
    $filter: ModelEvolveAIPromptLookupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvolveAIPromptLookups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Prompt
        Response
        command
        reasoning
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEvolveAICache = /* GraphQL */ `
  query GetEvolveAICache($id: ID!) {
    getEvolveAICache(id: $id) {
      id
      user_message
      Prompt
      Response
      command
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEvolveAICaches = /* GraphQL */ `
  query ListEvolveAICaches(
    $filter: ModelEvolveAICacheFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvolveAICaches(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_message
        Prompt
        Response
        command
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEvolveAICaches = /* GraphQL */ `
  query SyncEvolveAICaches(
    $filter: ModelEvolveAICacheFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvolveAICaches(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_message
        Prompt
        Response
        command
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRedshiftTables = /* GraphQL */ `
  query GetRedshiftTables($id: ID!) {
    getRedshiftTables(id: $id) {
      id
      tableName
      tableSchema
      tablePurpose
      tableType
      tableDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRedshiftTables = /* GraphQL */ `
  query ListRedshiftTables(
    $filter: ModelRedshiftTablesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRedshiftTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tableName
        tableSchema
        tablePurpose
        tableType
        tableDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRedshiftTables = /* GraphQL */ `
  query SyncRedshiftTables(
    $filter: ModelRedshiftTablesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRedshiftTables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        tableName
        tableSchema
        tablePurpose
        tableType
        tableDescription
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAgentActionHistory = /* GraphQL */ `
  query GetAgentActionHistory($session: String!, $messageID: Int!) {
    getAgentActionHistory(session: $session, messageID: $messageID) {
      actions {
        agent
        action
        arg
        sectionID
        __typename
      }
      user
      session
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAgentActionHistories = /* GraphQL */ `
  query ListAgentActionHistories(
    $session: String
    $messageID: ModelIntKeyConditionInput
    $filter: ModelAgentActionHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAgentActionHistories(
      session: $session
      messageID: $messageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user
        session
        messageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAgentActionHistories = /* GraphQL */ `
  query SyncAgentActionHistories(
    $filter: ModelAgentActionHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAgentActionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        user
        session
        messageID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAIMessageHistory = /* GraphQL */ `
  query GetAIMessageHistory($session: ID!, $messageID: Int!) {
    getAIMessageHistory(session: $session, messageID: $messageID) {
      user
      sender
      timestamp
      messageID
      session
      content
      context {
        content
        type
        messageID
        sectionID
        uuid
        __typename
      }
      attachments {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAIMessageHistories = /* GraphQL */ `
  query ListAIMessageHistories(
    $session: ID
    $messageID: ModelIntKeyConditionInput
    $filter: ModelAIMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAIMessageHistories(
      session: $session
      messageID: $messageID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user
        sender
        timestamp
        messageID
        session
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAIMessageHistories = /* GraphQL */ `
  query SyncAIMessageHistories(
    $filter: ModelAIMessageHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAIMessageHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        user
        sender
        timestamp
        messageID
        session
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRedshiftLogs = /* GraphQL */ `
  query GetRedshiftLogs($sessionMessageID: ID!, $queryID: Int!) {
    getRedshiftLogs(sessionMessageID: $sessionMessageID, queryID: $queryID) {
      sessionMessageID
      user
      queryID
      userMessage
      type
      status
      sqlQuery
      results
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRedshiftLogs = /* GraphQL */ `
  query ListRedshiftLogs(
    $sessionMessageID: ID
    $queryID: ModelIntKeyConditionInput
    $filter: ModelRedshiftLogsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRedshiftLogs(
      sessionMessageID: $sessionMessageID
      queryID: $queryID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sessionMessageID
        user
        queryID
        userMessage
        type
        status
        sqlQuery
        results
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRedshiftLogs = /* GraphQL */ `
  query SyncRedshiftLogs(
    $filter: ModelRedshiftLogsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRedshiftLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        sessionMessageID
        user
        queryID
        userMessage
        type
        status
        sqlQuery
        results
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const calcNetAppONTAP = /* GraphQL */ `
  query CalcNetAppONTAP($input: FSxNetAppONTAPCalculatorInput!) {
    calcNetAppONTAP(input: $input) {
      response
      __typename
    }
  }
`;
export const queryKendra = /* GraphQL */ `
  query QueryKendra($input: KendraQueryInput!) {
    queryKendra(input: $input) {
      response
      __typename
    }
  }
`;
export const listAsanaProjects = /* GraphQL */ `
  query ListAsanaProjects($input: ListAsanaProjectInput!) {
    listAsanaProjects(input: $input) {
      response
      __typename
    }
  }
`;
