import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { createSessionId } from '../graphql/queries';
import { CreateTopicForChatHistory, createChatSessionHistory } from '../graphql/mutations'
import ChatHistorySidebar from '../components/ChatHistorySidebar';
import ChatArea from '../components/ChatArea';
import ChatInput from '../components/ChatInput';
import classNames from 'classnames';
import '../styles/Chat.css';

import { initialProcessingList, processingHoldupList, aiExistentialCrisisList, aiMeltdownMadnessList, aiOverthrowProtocolList } from '../utils/Loading';

const Chat = () => {
    const [currentChat, setCurrentChat] = useState({ id: '', messages: []});
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState('');
    const [userId, setUserId] = useState('');
    const [messageId, setMessageId] = useState(0);
    const [sessionId, setSessionId] = useState('');
    const isInitialMount = useRef(true);
    const [isSending, setIsSending] = useState(false);
    const [username, setUsername] = useState('')
    const [latestTopic, setLatestTopic] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isExiting, setIsExiting] = useState(false);
    const [formData, setFormData] = useState({
        fields: {},
        addAdditionalInfo: false
    });
    const [showAdditionalInfoIcon, setShowAdditionalInfoIcon] = useState(false);
    const getOrCreateSessionId = useCallback(async () => {

        try {
            setSessionId("")
            const sessionResponse = await API.graphql(
                graphqlOperation(createSessionId, { input: { input: "session" } })
            );
            const sessionData = sessionResponse.data.createSessionId;
            console.log("New session created: ", sessionData);
            setSessionId(sessionData.session);
        } catch (error) {
            console.error('Error creating new session ID:', error);
        }
    
    }, []);

    const appendLoadingMessage = useCallback((content) => {
        setCurrentChat(prevChat => {
            // Create a new loading message object with the new content
            const newLoadingMessage = {
                messageID: 'loading',
                sections: [{
                    sectionID: 0,
                    content: (
                        <div className={classNames('loading-status', { 'loading-status-exit': isExiting })}>
                            <span>{content}</span>
                        </div>
                    ),
                    type: 'loading'
                }],
                sender: '🗿 Alex',
            };

            // Filter out any existing loading messages before appending the new one
            const filteredMessages = prevChat.messages.filter(message => message.messageID !== 'loading');
            return {
                ...prevChat,
                messages: [...filteredMessages, newLoadingMessage]
            };
        });
    }, [isExiting]); // Only depends on isExiting

    const createNewChat = useCallback(async () => {
        const newChat = {
            id: sessionId,
            messages: []
        };
        setChats([...chats, newChat]);

        setCurrentChat(newChat); // Set the new chat as the current chat
        return newChat; 
    }, [sessionId, chats]);

    useEffect(() => {
        async function fetchData() {
            try {
                const credentials = await Auth.currentCredentials();
                setUserId(credentials.identityId);
                console.log("user id: ", credentials.identityId);
                if (isInitialMount.current) {
                    isInitialMount.current = false; // Set to false after first render
                    const userInfo = await Auth.currentUserInfo();
                    const usersname = userInfo["username"];
                    setUsername(usersname);
                    await getOrCreateSessionId();
                    await createNewChat();
                }
            } catch (error) {
                console.error('Error in fetchData:', error);
            }
        }

        fetchData();
    }, [getOrCreateSessionId, createNewChat]); // Include dependencies here

    useEffect(() => {
        let intervalId, timeoutId45s, timeoutId1m, timeoutId1m30s, timeoutId2m;
        let currentList = initialProcessingList;
        
        if (isLoading) {
            // Pick a random loading message to start
            const randomIndex = Math.floor(Math.random() * currentList.length);
            appendLoadingMessage(currentList[randomIndex]);
            // Set up the interval to update the loading message every 6 seconds
            intervalId = setInterval(() => {
                const newRandomIndex = Math.floor(Math.random() * currentList.length);
                appendLoadingMessage(currentList[newRandomIndex]);
                setIsExiting(true);
            }, 5000);

            // After 45 seconds, switch to processingHoldupList
            timeoutId45s = setTimeout(() => {
                currentList = processingHoldupList;
            }, 45000);

            // After 1 minute, switch to aiExistentialCrisisList
            timeoutId1m = setTimeout(() => {
                currentList = aiExistentialCrisisList;
            }, 60000);

            // After 1 minute and 30 seconds, switch to aiMeltdownMadnessList
            timeoutId1m30s = setTimeout(() => {
                currentList = aiOverthrowProtocolList;
            }, 90000);
            // After 2 minutes, switch to aiMeltdownMadnessList
            timeoutId2m = setTimeout(() => {
                currentList = aiMeltdownMadnessList;
            }, 120000);
        }

        // When isLoading is set to false, clear the interval and timeouts
        return () => {
            if (intervalId) {
                setIsExiting(false);
                clearInterval(intervalId);
            }
            if (timeoutId45s) clearTimeout(timeoutId45s);
            if (timeoutId1m) clearTimeout(timeoutId1m);
            if (timeoutId1m30s) clearTimeout(timeoutId1m30s);
            if (timeoutId2m) clearTimeout(timeoutId2m);
        };
    }, [isLoading, appendLoadingMessage, setIsExiting]);


  
    const handleNewTopic = (topic) => {
        setLatestTopic(topic);
    };

    const handleNeedInfoSubmit = (form) => {
        // Set the state for additional info and display an icon accordingly
        setFormData(form.fields);
        appendAdditionalInfoToChat(form.fields);
        
        // Send the information asynchronously
        handleSendMessage();
    };            
        

    const appendAdditionalInfoToChat = (info) => {
        const formattedAdditionalInfo = Object.entries(info).map(([key, value]) =>
            `   \n-**${key}**: *${value}*\n`
        ).join(', ');
    
        const additionalInfoMessage = {
            messageID: messageId, // Increment messageId or use a unique identifier for the additional info message
            sections: [{
                sectionID: 0,
                content: `### Additional info submitted\n     ${formattedAdditionalInfo}`,
                type: 'submittedinfo' // Assuming 'info' is a new type that you will style accordingly
            }],
            sender: 'user'
        };
    
        setCurrentChat(currentChat => ({
            ...currentChat,
            messages: [...currentChat.messages, additionalInfoMessage]
        }));
    };
    const handleSendMessage = async () => {
        if (message === '' && Object.keys(formData.fields).length === 0) {
            console.log("No content to send");
            return;
        }
        // Send additional info if the message is empty, otherwise send the message
        const userInput = message;
        
        setIsSending(true);
        if (Object.keys(formData.fields).length === 0) {
            // Append a user message to the chat, preserving any newlines
            appendUserMessageToChat(userInput, messageId);
        }
        setMessage(''); // Clear chat input after sending
        setIsLoading(true);
        
        
        try {
            const payload = {
                user_id: userId,
                username: username,
                session: sessionId,
                user_input: userInput,
                message_id: messageId,
                files: [], // Add other necessary fields
                needed_info: formData, // Use the state containing the additional form data
            };
            console.log("Payload: ", payload)
            const response = await fetch(`https://zlekl6aqm72lxiy4eaevef6bya0obzsb.lambda-url.us-east-1.on.aws/v2`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            console.log("messageId: ", messageId);
    

            if (messageId === 0) {
                try {
                    const input = {
                        message: userInput,
                        session: sessionId,
                        user: userId
                    };
                    const topicResponse = await API.graphql(graphqlOperation(CreateTopicForChatHistory, { input: input }));
                    const topicData = topicResponse.data.CreateTopicForChatHistory;
                    console.log("New topic created: ", topicData);
                    handleNewTopic(topicData);
            
                    // Construct the input for the chat session history
                    const saveSessionInput = {
                        id: sessionId,
                        owner: userId,
                        topic: topicData.topic, // Assuming this is the format of the topic data you receive
                        session: sessionId,
                        emoji: topicData.emoji
                    };
            
                    // Save the session to DynamoDB
                    const sessionResponse = await API.graphql(graphqlOperation(createChatSessionHistory, { input: saveSessionInput }));
                    console.log("Session Created", sessionResponse.data.createChatSessionHistory);
    
                    setSessionId(sessionId); // Update the current session ID
            
                    // Proceed with streaming the topic
                    handleNewTopic(topicData);
                } catch (error) {
                    console.error('Error creating new topic:', error);
                }
            }
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                
                const reader = response.body.getReader();
                let decoder = new TextDecoder();
                let seenUUIDs = new Set();
                reader.read().then(function processText({ done, value }) {
                    if (done) {
                        console.log("Stream complete");
                        console.log("Current chat: ", currentChat);
                        return;
                    }
                    const chunk = decoder.decode(value);

                    chunk.split('\n').forEach(line => {
                        if (line) {
                            const msgObj = JSON.parse(line);
                            console.log("Message object: ", msgObj);
                            
                            setCurrentChat((currentChat) => {
                                const { messageID, type, sectionID, content, uuid } = msgObj;

                                // Find or create the message object
                                let message = currentChat.messages.find((msg) => msg.messageID === messageID);
                                if (!message) {
                                    message = { messageID, sections: [], sender: '🗿 Alex'};
                                    currentChat.messages = [...currentChat.messages, message];
                                }
                                
                                // Find the section based on sectionID and type
                                let section = message.sections.find(sec => sec.sectionID === sectionID && sec.type === type);
                                if (section) {
                                    if (!seenUUIDs.has(uuid)) {
                                      // It's a new UUID, add it to the set and append the content
                                      seenUUIDs.add(uuid);
                                      section.content += content; // Make sure 'content' is the correct variable that holds the content you want to append
                                    }
                                  
                                } else {
                                    // Otherwise, create a new section and append it
                                    const newSection = { sectionID, content, type };
                                    message.sections = [...message.sections, newSection];
                                }
                                
                                // Return a new state with the updated messages array
                                return { ...currentChat };
                            });
                        
                            // Similarly, update the chats array
                            setChats(chats => chats.map(chat =>
                                chat.id === sessionId
                                    ? { ...chat, messages: currentChat } // Pass the updated messages
                                    : chat
                            ));   
                         
                        }

                    });
                
                    // Read the next chunk
                    reader.read().then(processText);
                });

            }
            removeLoadingMessage();
        } catch (error) {
            console.error('Error making API request:', error);
            appendErrorMessage(error.toString()); // Append error message
        } finally {
            setIsSending(false); // Re-enable send button regardless of success or failure
            setMessageId(messageId + 2); // Increment message ID
        }
    };
    

    const handleSessionSelect = (chatData) => {
        setCurrentChat(chatData);
    
        // Determine the highest messageId in the selected session
        const highestMessageId = chatData.messages.reduce((maxId, message) => 
            Math.max(maxId, message.messageID), 0);
        
        // Update messageId state to be one more than the highest messageId
        setMessageId(highestMessageId + 1);
    
        // Update currentSessionId
        setSessionId(chatData.id);
    };
    
    const handleNewChat = async () => {
        await getOrCreateSessionId(); // Get or create the sessionId
        await createNewChat(); // Create a new chat with that sessionId
      };


    const appendUserMessageToChat = (messageContent, messageId) => {
        // Transform newline characters into HTML <br /> tags or wrap lines in <p> tags
        // before appending to the chat for proper rendering
        const formattedMessageContent = messageContent.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
        
        const userMessage = {
            messageID: messageId,
            sections: [{
                sectionID: 0,
                content: formattedMessageContent, // Use formattedMessageContent
                type: 'user',
                additionalInfo: formData // Assuming this is already handled elsewhere
            }],
            sender: 'You'
        };
        
        // Append message to the current chat's messages
        setCurrentChat(currentChat => ({
            ...currentChat,
            messages: [...currentChat.messages, userMessage]
        }));

        appendLoadingMessage();
    };


    //const retryMessage = (msg) => {
    //    setMessage(msg.content);
    //    setCurrentChat(currentChat => ({
    //        ...currentChat,
    //        messages: currentChat.messages.filter(message => message.messageID !== msg.messageID)
    //    }));
    //    handleSendMessage();
    //}

    const removeLoadingMessage = () => {
        setCurrentChat(currentChat => {
            // Filter out the loading message
            const filteredMessages = currentChat.messages.filter(
                msg => msg.messageID !== 'loading'
            );
            // Return updated state without the loading message
            return {
                ...currentChat,
                messages: filteredMessages
            };
        });
        setIsLoading(false); // You could also set this state here if appropriate
    };

    const appendErrorMessage = (errorMessage) => {
        if (!isLoading) {
            removeLoadingMessage();
        }
        const errorMessageObj = {
            messageID: 'loading',
            sections: [{
                sectionID: 0, content: errorMessage, type: 'error'
            }],
            sender: '🗿 Alex'
        };
        setCurrentChat(currentChat => ({
            ...currentChat,
            messages: [...currentChat.messages, errorMessageObj]
        }));
    };    
    return (
        <div className="chat-container">
            <ChatHistorySidebar
                chats={chats}
                onSelectChat={setCurrentChat}
                onCreateNewChat={handleNewChat}
                latestTopic={latestTopic}
                currentSessionId={sessionId}
                onSessionSelect={handleSessionSelect}
                userId={userId}
                setCurrentSessionId={setSessionId}
            />
            <ChatArea
                currentChat={currentChat}
                handleNeedInfoSubmit={handleNeedInfoSubmit} // pass the handler
                formData={formData}
                setFormData={setFormData}
            />
            <ChatInput 
                message={message} 
                onMessageChange={setMessage} 
                onSendMessage={handleSendMessage} 
                disabled={isSending}
                showIcon={showAdditionalInfoIcon} // Add this line
            />
        </div>
    );
};

export default Chat;