/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d7f94c9c-cfa7-44e4-bd34-61f54cb09683",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_3KeEceP9d",
    "aws_user_pools_web_client_id": "52t2c9i2uafr8qpnhcrcb4tja3",
    "oauth": {
        "domain": "auth-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://evolve-cap.com/callback/",
        "redirectSignOut": "https://evolve-cap.com/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "evolvedashamplifystorage165316-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://vta2pbnbrjaw7gewqlz3du3eti.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://gc9qfx5eal.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
