import React, { useState } from 'react';

function Task({ task }) {
  const taskStatusClass = task.completed ? 'task-card-completed' : 'task-card-in-progress';
  
  const [showCustomFields, setShowCustomFields] = useState(false);
  
  const toggleCustomFields = () => {
    setShowCustomFields(!showCustomFields);
  };

  const renderCustomFields = (fields) => fields.map((field) => {
    const value = field.text_value || field.number_value;
    if (value) {
      return (
        <p key={field.gid} className="task-custom-field">
          {field.name}: {value}
        </p>
      );
    }
    return null;
  }).filter(Boolean); // Clean up any `null` entries

  return (
    <div className={`task-card ${taskStatusClass}`}>
      <h3 className="task-name">{task.name} <span className="task-id">#{task.gid}</span></h3>
      <div className="task-meta">
        <p className="task-assignee"><i className="fas fa-user-circle"></i> {task.assignee ? task.assignee.name : 'No soul assigned yet'}</p>
        <p className="task-status"><i className="fas fa-tasks"></i> Status: {task.resource_subtype || 'No status'}</p>
        <p className="task-due"><i className="fas fa-calendar-alt"></i> Due on: {task.due_on || 'No due date'}</p>
        <p className="task-created"><i className="fas fa-clock"></i> Created: {new Date(task.created_at).toLocaleDateString()}</p>
        {task.completed_by && <p className="task-completed-by"><i className="fas fa-check-circle"></i> Completed by: {task.completed_by.name}</p>}
        <div className="task-custom-fields-toggle" onClick={toggleCustomFields}>
          <i className={`fas ${showCustomFields ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i> Custom Fields
        </div>
        {showCustomFields && (
        <div className="task-custom-fields">
          {renderCustomFields(task.custom_fields)}
        </div>
      )}
      </div>
      {task.html_notes && <div className="task-notes" dangerouslySetInnerHTML={{ __html: task.html_notes }} />}
      <a href={task.permalink_url} target="_blank" rel="noopener noreferrer" className="task-link"><i className="fas fa-external-link-alt"></i> View in Asana</a>
    </div>
  );
}

export default Task;