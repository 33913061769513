
import React from 'react';
import { Amplify} from 'aws-amplify';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsmobile from './aws-exports';
import SidePanel from './components/SidePanel';
import MLSReconcile from './pages/Mls';
import Search from './pages/Search';
import Tasks from './pages/Tasks';
import Chat from './pages/Chat';
import { Hub } from 'aws-amplify';

Amplify.configure(awsmobile);

function App(user, signOut) {
  const listener = (data) => {
    console.log(data);
  };
  Hub.listen('auth', listener);
  return (
    <Router>
        <SidePanel user={user} signOut={signOut}/>
        <Routes>
            <Route path='/mls' element={<MLSReconcile />} />
            <Route path='/search' element={<Search />} />
            <Route path='/tasks' element={<Tasks />} />
            <Route path='/ai' element={<Chat />} />
        </Routes>
    </Router>

  );
}

export default withAuthenticator(App);