import React, { useState, Fragment } from 'react';
import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { queryKendra } from '../graphql/queries';
import '../styles/Search.css';
import MainContent from '../containers/MainContent';
import SectionContainer from '../containers/SectionContainer';
import { mdiBookOpenPageVariant, mdiLoading, mdiAlertCircle, mdiMagnify, mdiCloudQuestion, mdiOpenInApp, mdiFrequentlyAskedQuestions, mdiClose, mdiFileDocument, mdiWeb, mdiOpenInNew, mdiPlus } from '@mdi/js';
import { Icon } from '@mdi/react';
import { createNewFaq, editExistingFaq, removeExistinFaq } from '../graphql/mutations';
import FaqCreateModal from '../components/FaqCreateModal'; // Import this component
import FaqItem from '../components/FaqItem';
import FaqForm from '../components/FaqForm';
import HighlightedText from '../components/HighlightText';

const searchInKendra = async (query) => {
  const response = await API.graphql(graphqlOperation(queryKendra, { input: { query: query } }));
  console.log("response: ", response);
  return response.data.queryKendra;
};


const PDFViewer = ({ src, onClose }) => {
  if (!src) return null;  // Early return if no source passed

  return (
    <div className="pdf-viewer">
      <div className="backdrop" onClick={onClose} />
      <div className="viewer">
        <iframe
          src={src}
          width="100%"
          height="100%"
          style={{ border: 'none' }}
          title="PDF Viewer"
        ></iframe>
        <button className="close-btn" onClick={onClose}>
          <Icon path={mdiClose} size={.75} />
        </button>
      </div>
    </div>
  );
};


  const Search = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState({ FAQs: [], Documents: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [pdfToView, setPdfToView] = useState(null);
    const [viewerOpen, setViewerOpen] = useState(false);
    const [uniqueDocTitles, setUniqueDocTitles] = useState(new Set());
    const [isInitial, setIsInitial] = useState(true);
    const [faqFormVisible, setFaqFormVisible] = useState(false);
    const [editableFaq, setEditableFaq] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [currentQuery, setCurrentQuery] = useState('');

    const handleCreateFaq = async (faq) => {
      const user = await Auth.currentAuthenticatedUser();
      const userId = user.username;
      try {
        const input = {
          question: faq.question,
          answer: faq.answer,
          FAQName: 'default',
          user: userId,
        };
        const newFaqResponse = await API.graphql(
          graphqlOperation(createNewFaq, { input })
        );
        console.log('New FAQ created:', newFaqResponse);
      } catch (e) {
        console.error('Failed to create FAQ:', e);
      }
    };

    const handleEditFaq = (faqItem) => {
      setEditableFaq({
        id: faqItem.Id,
        question: faqItem.AdditionalAttributes.find(attr => attr.Key === 'QuestionText').Value.TextWithHighlightsValue.Text,
        answer: faqItem.AdditionalAttributes.find(attr => attr.Key === 'AnswerText').Value.TextWithHighlightsValue.Text
      });
      setFaqFormVisible(true);
    };

    const handleUpdateFaq = async (updatedFaq) => {
      try {
        const input = {
          id: editableFaq.id,
          question: updatedFaq.question,
          answer: updatedFaq.answer,
        };
        await API.graphql(graphqlOperation(editExistingFaq, { input }));
        setFaqFormVisible(false);
      } catch (error) {
        console.error('Failed to update FAQ:', error);
      }
    };

    const handleDeleteFaq = async (faqId) => {
      try {
        const input = {
          faqId: faqId,
        };
        const deleteFaqResponse = await API.graphql(
          graphqlOperation(removeExistinFaq, { input })
        );
        console.log('FAQ deleted:', deleteFaqResponse);
      } catch (e) {
        console.error('Failed to delete FAQ:', e);
      }
    };

    const handleSearch = async () => {
      setCurrentQuery(searchQuery);
      setUniqueDocTitles(new Set());
      setIsLoading(true);
      setSearchResults({ FAQs: [], Documents: [] });
      setError(null);
      try {
        const results = await searchInKendra(searchQuery);
        if (results && results.response) {
          const parsedResults = JSON.parse(results.response);
          const FAQs = parsedResults.filter(item => item.Type === 'QUESTION_ANSWER');
          const Documents = parsedResults
            .filter(item => item.Type !== 'QUESTION_ANSWER')
            .filter(doc => {
              const title = doc.DocumentTitle.Text;
              if (uniqueDocTitles.has(title)) {
                return false;
              }
              uniqueDocTitles.add(title);
              return true;
            });

          setSearchResults({ FAQs, Documents });
        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error during search:', error);
        setError(error.toString());
      }
      setIsInitial(false);
      setIsLoading(false);
    };

    const openDocument = async (document) => {
      const sourceUriAttribute = document.DocumentAttributes.find(attr => attr.Key === '_source_uri');
      if (sourceUriAttribute && sourceUriAttribute.Value && sourceUriAttribute.Value.StringValue) {
        let sourceUri = sourceUriAttribute.Value.StringValue;

        if (!sourceUri.includes('https://evolve-ai-documents.s3.amazonaws.com/data/')) {
          window.open(sourceUri, '_blank');
          return;
        }
        const docId = sourceUri.replace(
          'https://evolve-ai-documents.s3.amazonaws.com/data/',
          'documents/'
        );
        sourceUri = sourceUri.replace(
          'https://evolve-ai-documents.s3.amazonaws.com/data/',
          'https://evolvedashamplifystorage165316-staging.s3.us-east-1.amazonaws.com/public/documents/'
        );
        const auth = await Auth.currentAuthenticatedUser();
        console.log("auth:", auth);
        if (sourceUri.toLowerCase().endsWith('.pdf')) {
          const pdfFile = await Storage.get(docId, {
            level: 'public',
            contentType: 'pdf'
          });
          console.log("pdf: ", pdfFile)
          setPdfToView(pdfFile);
          setViewerOpen(true);
        } else {
          window.location.href = sourceUri;
        }
      } else {
        console.error('Source URI not found for document.');
      }
    };

    return (
      <div className="search-container">
        <div className="search-bar">
          <input
            className="search-input"
            type="text"
            placeholder="Type to search..."
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
          <button onClick={handleSearch} className="search-button animated-pulse">
            <Icon path={mdiMagnify} size={.75} className="button-icon" />
          </button>
        </div>
        <MainContent>
          {isLoading ? (
              <SectionContainer>
                <div className="loading-container">
                  <Icon path={mdiLoading} size={2} className="loading-icon" />
                  <p>Loading...</p>
                </div>
              </SectionContainer>
            ) : (
            isInitial ? (
              <SectionContainer>
                <div>
                  <p>Enter Search query for results</p>
                </div>
              </SectionContainer>
            ) : error ? (
              <SectionContainer>
                <div className="faq-container">
                  <Icon path={mdiAlertCircle} size={2} className="error-icon" />
                  <p>{error}</p>
                </div>
              </SectionContainer>
            ) : (
              <Fragment>
                {searchResults.FAQs.length > 0 ? (
                  <SectionContainer>
                    <div className="faq-container">
                      <h2 className="faq-title">
                        <Icon path={mdiFrequentlyAskedQuestions} size={1} className="section-icon" />
                        FAQs
                      </h2>
                      {searchResults.FAQs.map((item, index) => (
                        <FaqItem
                          key={item.id || index}
                          item={item}
                          onEdit={handleEditFaq}
                          onDelete={handleDeleteFaq}
                        />
                      ))}
                    </div>
                    <button className='no-results-create' onClick={() => setShowCreateModal(true)}>
                        Create FAQ<Icon path={mdiPlus} size={.7} />
                    </button>
                    {faqFormVisible && (
                      <FaqForm
                        initialData={editableFaq}
                        onClose={() => setFaqFormVisible(false)}
                        show={faqFormVisible}
                        onSubmit={editableFaq ? handleUpdateFaq : handleCreateFaq}
                      />
                    )}
                  </SectionContainer>
                ) : (
                  <SectionContainer>
                    <div className='faq-container'>
                      <h2 className="faq-title">
                        <Icon path={mdiFrequentlyAskedQuestions} size={1} className="section-icon" />
                        FAQs
                      </h2>
                      <p>No FAQs found for "{currentQuery}".</p>
                      <button className='no-results-create' onClick={() => setShowCreateModal(true)}>
                        Create FAQ<Icon path={mdiPlus} size={.7} />
                      </button>
                    </div>
                  </SectionContainer>
                )}
                {searchResults.Documents.length > 0 ? (
                  <SectionContainer>
                    <div className="document-container">
                      <h2 className="title"><Icon path={mdiFileDocument} size={1} className="section-icon" /> Documents</h2>
                      {searchResults.Documents.map((item, index) => {
                        const sourceUriAttribute = item.DocumentAttributes.find(attr => attr.Key === '_source_uri');
                        const isExternal = sourceUriAttribute && !sourceUriAttribute.Value.StringValue.includes('https://evolve-ai-documents.s3.amazonaws.com/data/');
                        return (
                          <div key={item.Id || index} className="document-item">
                            <div className="title-container" onClick={() => openDocument(item)}>
                              <Icon path={isExternal ? mdiWeb : mdiFileDocument} size={1} className="title-icon" />
                              <h3>{item.DocumentTitle.Text || 'Untitled Document'}</h3>
                              <Icon
                                path={isExternal ? mdiOpenInNew : mdiOpenInApp}
                                size={1}
                                className="open-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openDocument(item);
                                }}
                              />
                            </div>
                            <div className="document-excerpt">
                              <HighlightedText text={item.DocumentExcerpt.Text} highlights={item.DocumentExcerpt.Highlights} />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </SectionContainer>
                ) : (
                  <SectionContainer>
                    <div className='document-container'>
                      <p>No documents found for {currentQuery}. <a className='no-results-create'>Upload Document/s/<Icon path={mdiPlus} size={.7} /></a></p>
                    </div>
                  </SectionContainer>
                )}
              </Fragment>
            ))
          }
        {showCreateModal && (
          <FaqCreateModal onClose={() => setShowCreateModal(false)} />
        )}
        {viewerOpen && (
          <PDFViewer
            src={pdfToView}
            onClose={() => setViewerOpen(false)} // Close button functionality
          />
          )}
        </MainContent>
      </div>
    );
  };


export default Search;