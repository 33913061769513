import React, { useEffect, useRef } from 'react';
import ChatMessages from './ChatMessages';
import '../styles/ChatArea.css';
const ChatArea = ({ currentChat, handleNeedInfoSubmit, formData, setFormData }) => {
    const messagesEndRef = useRef(null);

    useEffect(() => {
        if (currentChat && currentChat.messages.length > 0) {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [currentChat]);

    if (!currentChat) {
        return <div className="chat-area__placeholder">No chat selected</div>;
    }


    return (
        <div className="chat-area">
            <ChatMessages
                currentChat={currentChat}
                handleNeedInfoSubmit={handleNeedInfoSubmit}
                formData={formData}
                setFormData={setFormData}
            />
            <div ref={messagesEndRef} />
        </div>
    );
};

export default ChatArea;
