
// FaqCreateModal.jsx
import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createKendraFaq } from '../graphql/mutations';
import { Icon } from '@mdi/react';
import { mdiClose } from '@mdi/js';

const FaqCreateModal = ({ onClose }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Ensure the question ends with a question mark '?'
    const formattedQuestion = question.trim().endsWith('?') ? question.trim() : `${question.trim()}?`;

    const input = {
      question: formattedQuestion,
      answer: answer.trim(),
      // Add other required fields if necessary
    };

    try {
      await API.graphql(graphqlOperation(createKendraFaq, { input }));
      onClose(); // Close modal on success
    } catch (error) {
      console.error('Error creating new FAQ:', error);
    }
  };

  return (
    <div className="edit-modal">
      <div className="modal-content">
        <button className="close-modal" onClick={onClose}>
          <Icon path={mdiClose} size={1} />
        </button>
        <form onSubmit={handleSubmit}>
          <textarea
            value={question}
            placeholder="Enter question..."
            onChange={(e) => setQuestion(e.target.value)}
            required
          />
          <textarea
            value={answer}
            placeholder="Enter answer..."
            onChange={(e) => setAnswer(e.target.value)}
            required
          />
          <button type="submit">Create FAQ</button>
        </form>
      </div>
    </div>
  );
};

export default FaqCreateModal;