
// FaqForm.jsx
import React, { useState, useEffect, useRef } from 'react';
import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';

// CSS transitions classes
const transitionClasses = {
  entering: 'modal-entering',
  entered: 'modal-entered',
  exiting: 'modal-exiting',
  exited: 'modal-exited',
};

const FaqForm = ({ mode, initialData = {}, onSubmit, onClose, show }) => {
  const [question, setQuestion] = useState(initialData.question || '');
  const [answer, setAnswer] = useState(initialData.answer || '');

  // Use a ref for the textarea to manage auto-resizing
  const textareaRef = useRef(null);

  // Auto-resize the textarea on mount and when the answer changes
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [answer]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await onSubmit({ question, answer });
    setQuestion('');
    setAnswer('');
  };

  // Determine the transition class to apply based on visibility
  const modalClass = transitionClasses[show ? 'entered' : 'exited'];

  return (
    <div className={`edit-modal ${modalClass}`}>
      <div className="modal-content">
        <button className="close-modal" onClick={onClose}>
          <Icon path={mdiClose} size={1} />
        </button>
        <input
          type="text"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          placeholder="Type your question here"
          required
        />
        <textarea
          ref={textareaRef}
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
          placeholder="Type the answer here"
          required
        />
        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default FaqForm;