import React, { useState } from 'react';
import '../styles/ProjectHeader.css';
import { SiAlteryx, SiVmware } from "react-icons/si";
import Icon from '@mdi/react';

const ProjectHeader = ({ onProjectSelect, projectsConfig }) => {
    const [selectedProject, setSelectedProject] = useState('OLA');
    const [previousSelectedProject, setPreviousSelectedProject] = useState('');

    const handleProjectSelect = (projectKey) => {
        if (selectedProject !== projectKey) {
            setPreviousSelectedProject(selectedProject);
            setSelectedProject(projectKey);
            onProjectSelect(projectKey);
        }
    };

    const getProjectStyle = (projectKey) => {
        const isSelected = projectKey === selectedProject;
        const isPreviouslySelected = projectKey === previousSelectedProject;
        let style = {
            flex: 1, 
            ...projectsConfig[projectKey].style,
            ...(isSelected ? projectsConfig[projectKey].selectedStyle : projectsConfig[projectKey].notSelectedStyle),
            transform: 'skew(-45deg)'
        };
        if (!isSelected && !isPreviouslySelected) {
            style = {
                ...style,
                ...projectsConfig[projectKey].notSelectedStyle
            };
        }
        return style;
    };

    const renderProjectContent = (projectKey) => {
        const project = projectsConfig[projectKey];
        if (projectKey === 'GCP') {
            return renderGcpTitle(projectKey);
        } else if (projectKey === 'VMWare') {
            return <SiVmware size={60} className={`vmware-project-title ${projectKey === selectedProject ? 'selected' : ''}`} />;
        } else if (projectKey === 'ALTERYX') {
            return <SiAlteryx size={60} className={`alteryx-project-title ${projectKey === selectedProject ? 'selected' : ''}`} />;
        } else if (projectKey === 'OLA') {
            return (
                <div>
                    <span className={`ola-project-title ${projectKey === selectedProject ? 'selected' : ''}`}>{project.title}<Icon path={project.icon} size={1} className={project.iconStyle} /></span>
                </div>
            );
        } else if (projectKey === 'ProServe') {
            return (
                <div>
                    <span className={`proserve-project-title ${projectKey === selectedProject ? 'selected' : ''}`}>{project.title}<Icon path={project.icon} size={1} className={project.iconStyle} /></span>
                </div>
            );
        } else if (projectKey === 'Evolve AI') {
            return (
                <div>
                    <span className={`evolveai-project-title ${projectKey === selectedProject ? 'selected' : ''}`}>{project.title}<Icon path={project.icon} size={1} className={project.iconStyle} /></span>
                </div>
            );
        }
    };

    const renderGcpTitle = (projectKey) => {
        const googleLetters = ['G', 'o', 'o', 'g', 'l', 'e'];
        const googleColors = ['#4285F4', '#DB4437', '#F4B400', '#4285F4', '#0F9D58', '#DB4437'];
        return googleLetters.map((letter, index) => (
            <span key={letter} style={{ color: googleColors[index], fontSize: `${projectKey === selectedProject ? '25px' : '40px'}`, left: `${projectKey === selectedProject ? '15%' : '0%'}`, top: `${projectKey === selectedProject ? '25px' : '8%'}`, position: 'relative' }}>
                {letter}
            </span>
        ));
    };

    return (
        <div className="project-header-container">
            {Object.keys(projectsConfig).map((projectKey) => (
                <div
                    key={projectKey}
                    className={`project-option ${projectKey === selectedProject ? 'selected' : 'unselected'}`}
                    style={getProjectStyle(projectKey)}
                    onClick={() => handleProjectSelect(projectKey)}
                >
                    <div style={{ transform: `skew(45deg)` }}> {/* Counter-skew for content */}
                        {renderProjectContent(projectKey)}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectHeader;
