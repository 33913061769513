
import React, { useEffect, useRef } from 'react';
import '../styles/ChatMessages.css';
import ChatSections from './ChatSections';

const ChatMessages = ({ currentChat, handleNeedInfoSubmit, formData, setFormData }) => {
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);



    return (
        <div className="chat-messages initial-margin" ref={messagesContainerRef}>
            {currentChat.messages.map((msg, index) => (
                <div key={msg.messageID || index} className="message">
                    <span className='message-author'>{msg.sender === '🗿 Alex' ? '🗿 Alex' : msg.sender}</span>
                    <ChatSections sections={msg.sections} handleNeedInfoSubmit={handleNeedInfoSubmit} formData={formData} setFormData={setFormData}/>
                </div>
            ))}
            <div ref={messagesEndRef} />
        </div>
    );
};

export default ChatMessages;