import React, { Fragment } from "react";
const HighlightedText = ({ text, highlights }) => {
    if (!highlights) return <>{text}</>;
  
    highlights.sort((a, b) => a.BeginOffset - b.BeginOffset);
    const textParts = [];
    let lastIndex = 0;
  
    highlights.forEach(({ BeginOffset, EndOffset }, index) => {
      const nonHighlightedText = text.substring(lastIndex, BeginOffset);
      if (nonHighlightedText) {
        textParts.push(<Fragment key={`normal-${index}`}>{nonHighlightedText}</Fragment>);
      }
  
      const highlightedText = text.substring(BeginOffset, EndOffset);
      textParts.push(<span key={`highlight-${index}`} className="highlight">{highlightedText}</span>);
  
      lastIndex = EndOffset;
    });
  
    const lastNonHighlightedText = text.substring(lastIndex);
    textParts.push(<Fragment key={`final-normal`}>{lastNonHighlightedText}</Fragment>);
  
    return <>{textParts}</>;
  };
export default HighlightedText;