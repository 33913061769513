
import React from 'react';
import Icon from '@mdi/react';
import { NavLink } from 'react-router-dom';
import { mdiClockOutline, mdiViewDashboard, mdiMagnify, mdiFormatListChecks, mdiRobot, mdiHomeGroup, mdiFire, mdiCogTransfer, mdiTruckDelivery, mdiSecurity, mdiKeyChainVariant, mdiPresentation, mdiDeveloperBoard, mdiFileAccount } from '@mdi/js';
import '../styles/SidePanel.css';

const SidePanel = ({ user, signOut }) => {

  return (
    <div className="side-panel">
      <div className="side-panel-header">
        <img src='/evolve_header.png' className="side-panel-logo" alt="Evolve Logo" />
        <h1 className="side-panel-title">Evolve Cloud Platform</h1>
      </div>
        <ul className="side-panel-nav">
          <li data-icon="mdi-view-dashboard">
            <NavLink to="/">
              <Icon path={mdiViewDashboard} size={1} />
              Dashboard
            </NavLink>
          </li>
          <li data-icon="mdi-home-group">
            <NavLink to="/mls">
              <Icon path={mdiHomeGroup} size={1} />
              MLS
            </NavLink>
          </li>
          <li data-icon="mdi-magnify">
            <NavLink to="/search">
              <Icon path={mdiMagnify} size={1} />
              Search
            </NavLink>
          </li>
          <li data-icon="mdi-format-list-checks">
            <NavLink to="/tasks">
              <Icon path={mdiFormatListChecks} size={1} />
              Tasks
            </NavLink>
          </li>
          <li data-icon="mdi-robot">
            <NavLink to="/ai">
              <Icon path={mdiRobot} size={1} />
              AI
            </NavLink>
          </li>
          <li data-icon="mdi-fire">
            <NavLink to="/burnrate">
              <Icon path={mdiFire} size={1} />
              Credit BurnRate
            </NavLink>
          </li>
          <li data-icon="mdi-cog-transfer">
            <NavLink to="/assessment">
              <Icon path={mdiCogTransfer} size={1} />
              Assessment Config
            </NavLink>
          </li>
          <li data-icon="mdi-truck-delivery">
            <NavLink to="/delivery">
              <Icon path={mdiTruckDelivery} size={1} />
              Delivery
            </NavLink>
          </li>
          <li data-icon="mdi-security">
            <NavLink to="/admin">
              <Icon path={mdiSecurity} size={1} />
              Admin
            </NavLink>
          </li>
          <li data-icon="mdi-presentation">
            <NavLink to="/analyst">
              <Icon path={mdiPresentation} size={1} />
              Analyst
            </NavLink>
          </li>
          <li data-icon="mdi-developer-board">
            <NavLink to="/dev">
              <Icon path={mdiDeveloperBoard} size={1} />
              Dev
            </NavLink>
          </li>       
          <li data-icon="mdi-clock-outline">
            <NavLink to="/schedule">
              <Icon path={mdiClockOutline} size={1} />
              Schedule Assist
            </NavLink>
          </li>          
          <li data-icon="mdi-key-chain-variant">
            <NavLink to="/custody">
              <Icon path={mdiKeyChainVariant} size={1} />
              Chain Of Custody
            </NavLink>
          </li>    
        </ul>
      <div className="side-panel-profile" onClick={signOut}>
        <div className="profile-info">
          <div className="profile-picture"></div>
          <span className="profile-name">{user ? user.name : 'Guest'}</span>
        </div>
        {/* Menu can be toggled here */}
        <div className="profile-menu">
          {/* Add additional profile options as needed */}
        </div>
      </div>
    </div>
  );
};

export default SidePanel;