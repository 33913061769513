
import React, { useEffect } from 'react';

import '../styles/ChatInput.css';
import autosize from 'autosize';
import Icon from '@mdi/react';
import { mdiAccountDetails } from '@mdi/js';
const ChatInput = ({ message, onMessageChange, onSendMessage, showIcon }) => {

    // Initialize autosize and update it when the message changes
    useEffect(() => {
        const textarea = document.querySelector('textarea');
        autosize(textarea);
        
        return () => {
            if (textarea) autosize.destroy(textarea);
        };
    }, []);


    useEffect(() => {
        // Update textarea when the component mounts or message changes
        autosize.update(document.querySelector('textarea'));
    }, [message]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !e.altKey && !e.ctrlKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onMessageChange(e.target.value); 
        onSendMessage(e.target.value);

    };

    return (
        <form className="chat-input" onSubmit={handleSubmit}>
            {showIcon && <Icon path={mdiAccountDetails} size={1} className="additional-info-icon"/>}
            <textarea
                value={message}
                onChange={(e) => onMessageChange(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder={showIcon ? "Enter additional information..." : "Type a message..."}
            />
            <button type="submit">Send</button>
        </form>
    );
};

export default ChatInput;