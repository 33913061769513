/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      title
      description
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPrivateNote = /* GraphQL */ `
  mutation CreatePrivateNote(
    $input: CreatePrivateNoteInput!
    $condition: ModelPrivateNoteConditionInput
  ) {
    createPrivateNote(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updatePrivateNote = /* GraphQL */ `
  mutation UpdatePrivateNote(
    $input: UpdatePrivateNoteInput!
    $condition: ModelPrivateNoteConditionInput
  ) {
    updatePrivateNote(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deletePrivateNote = /* GraphQL */ `
  mutation DeletePrivateNote(
    $input: DeletePrivateNoteInput!
    $condition: ModelPrivateNoteConditionInput
  ) {
    deletePrivateNote(input: $input, condition: $condition) {
      id
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createKendraFaq = /* GraphQL */ `
  mutation CreateKendraFaq(
    $input: CreateKendraFaqInput!
    $condition: ModelKendraFaqConditionInput
  ) {
    createKendraFaq(input: $input, condition: $condition) {
      id
      question
      answer
      FAQName
      previousQ
      previousA
      lastUpdatedBy
      lastUpdatedAt
      createdBy
      createdAt
      s3Path
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateKendraFaq = /* GraphQL */ `
  mutation UpdateKendraFaq(
    $input: UpdateKendraFaqInput!
    $condition: ModelKendraFaqConditionInput
  ) {
    updateKendraFaq(input: $input, condition: $condition) {
      id
      question
      answer
      FAQName
      previousQ
      previousA
      lastUpdatedBy
      lastUpdatedAt
      createdBy
      createdAt
      s3Path
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteKendraFaq = /* GraphQL */ `
  mutation DeleteKendraFaq(
    $input: DeleteKendraFaqInput!
    $condition: ModelKendraFaqConditionInput
  ) {
    deleteKendraFaq(input: $input, condition: $condition) {
      id
      question
      answer
      FAQName
      previousQ
      previousA
      lastUpdatedBy
      lastUpdatedAt
      createdBy
      createdAt
      s3Path
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatHistoryMessage = /* GraphQL */ `
  mutation CreateChatHistoryMessage(
    $input: CreateChatHistoryMessageInput!
    $condition: ModelChatHistoryMessageConditionInput
  ) {
    createChatHistoryMessage(input: $input, condition: $condition) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatHistoryMessage = /* GraphQL */ `
  mutation UpdateChatHistoryMessage(
    $input: UpdateChatHistoryMessageInput!
    $condition: ModelChatHistoryMessageConditionInput
  ) {
    updateChatHistoryMessage(input: $input, condition: $condition) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatHistoryMessage = /* GraphQL */ `
  mutation DeleteChatHistoryMessage(
    $input: DeleteChatHistoryMessageInput!
    $condition: ModelChatHistoryMessageConditionInput
  ) {
    deleteChatHistoryMessage(input: $input, condition: $condition) {
      id
      owner
      content
      sender
      context
      type
      backend
      timestamp
      session {
        id
        owner
        topic
        session
        timestamp
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatHistorySession = /* GraphQL */ `
  mutation CreateChatHistorySession(
    $input: CreateChatHistorySessionInput!
    $condition: ModelChatHistorySessionConditionInput
  ) {
    createChatHistorySession(input: $input, condition: $condition) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatHistorySession = /* GraphQL */ `
  mutation UpdateChatHistorySession(
    $input: UpdateChatHistorySessionInput!
    $condition: ModelChatHistorySessionConditionInput
  ) {
    updateChatHistorySession(input: $input, condition: $condition) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatHistorySession = /* GraphQL */ `
  mutation DeleteChatHistorySession(
    $input: DeleteChatHistorySessionInput!
    $condition: ModelChatHistorySessionConditionInput
  ) {
    deleteChatHistorySession(input: $input, condition: $condition) {
      id
      owner
      topic
      session
      timestamp
      messages {
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatMessageHistory = /* GraphQL */ `
  mutation CreateChatMessageHistory(
    $input: CreateChatMessageHistoryInput!
    $condition: ModelChatMessageHistoryConditionInput
  ) {
    createChatMessageHistory(input: $input, condition: $condition) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      uiHistory {
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatMessageHistory = /* GraphQL */ `
  mutation UpdateChatMessageHistory(
    $input: UpdateChatMessageHistoryInput!
    $condition: ModelChatMessageHistoryConditionInput
  ) {
    updateChatMessageHistory(input: $input, condition: $condition) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      uiHistory {
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatMessageHistory = /* GraphQL */ `
  mutation DeleteChatMessageHistory(
    $input: DeleteChatMessageHistoryInput!
    $condition: ModelChatMessageHistoryConditionInput
  ) {
    deleteChatMessageHistory(input: $input, condition: $condition) {
      owner
      content
      sender
      context
      type
      timestamp
      messageID
      session
      uiHistory {
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChatSessionHistory = /* GraphQL */ `
  mutation CreateChatSessionHistory(
    $input: CreateChatSessionHistoryInput!
    $condition: ModelChatSessionHistoryConditionInput
  ) {
    createChatSessionHistory(input: $input, condition: $condition) {
      id
      owner
      topic
      emoji
      session
      timestamp
      redshiftSuperTables
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChatSessionHistory = /* GraphQL */ `
  mutation UpdateChatSessionHistory(
    $input: UpdateChatSessionHistoryInput!
    $condition: ModelChatSessionHistoryConditionInput
  ) {
    updateChatSessionHistory(input: $input, condition: $condition) {
      id
      owner
      topic
      emoji
      session
      timestamp
      redshiftSuperTables
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChatSessionHistory = /* GraphQL */ `
  mutation DeleteChatSessionHistory(
    $input: DeleteChatSessionHistoryInput!
    $condition: ModelChatSessionHistoryConditionInput
  ) {
    deleteChatSessionHistory(input: $input, condition: $condition) {
      id
      owner
      topic
      emoji
      session
      timestamp
      redshiftSuperTables
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNotes = /* GraphQL */ `
  mutation CreateNotes(
    $input: CreateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    createNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateNotes = /* GraphQL */ `
  mutation UpdateNotes(
    $input: UpdateNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    updateNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteNotes = /* GraphQL */ `
  mutation DeleteNotes(
    $input: DeleteNotesInput!
    $condition: ModelNotesConditionInput
  ) {
    deleteNotes(input: $input, condition: $condition) {
      session
      name
      messageID
      note
      model
      thought
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContext = /* GraphQL */ `
  mutation CreateContext(
    $input: CreateContextInput!
    $condition: ModelContextConditionInput
  ) {
    createContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContext = /* GraphQL */ `
  mutation UpdateContext(
    $input: UpdateContextInput!
    $condition: ModelContextConditionInput
  ) {
    updateContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContext = /* GraphQL */ `
  mutation DeleteContext(
    $input: DeleteContextInput!
    $condition: ModelContextConditionInput
  ) {
    deleteContext(input: $input, condition: $condition) {
      session
      name
      relation
      context
      timestamp
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInstruct = /* GraphQL */ `
  mutation CreateInstruct(
    $input: CreateInstructInput!
    $condition: ModelInstructConditionInput
  ) {
    createInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInstruct = /* GraphQL */ `
  mutation UpdateInstruct(
    $input: UpdateInstructInput!
    $condition: ModelInstructConditionInput
  ) {
    updateInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInstruct = /* GraphQL */ `
  mutation DeleteInstruct(
    $input: DeleteInstructInput!
    $condition: ModelInstructConditionInput
  ) {
    deleteInstruct(input: $input, condition: $condition) {
      session
      instruction
      reason
      directTo
      messageID
      userId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAWSServices = /* GraphQL */ `
  mutation CreateAWSServices(
    $input: CreateAWSServicesInput!
    $condition: ModelAWSServicesConditionInput
  ) {
    createAWSServices(input: $input, condition: $condition) {
      id
      dateOfLastChange
      numberOfChanges
      changes
      currentVersion
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAWSServices = /* GraphQL */ `
  mutation UpdateAWSServices(
    $input: UpdateAWSServicesInput!
    $condition: ModelAWSServicesConditionInput
  ) {
    updateAWSServices(input: $input, condition: $condition) {
      id
      dateOfLastChange
      numberOfChanges
      changes
      currentVersion
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAWSServices = /* GraphQL */ `
  mutation DeleteAWSServices(
    $input: DeleteAWSServicesInput!
    $condition: ModelAWSServicesConditionInput
  ) {
    deleteAWSServices(input: $input, condition: $condition) {
      id
      dateOfLastChange
      numberOfChanges
      changes
      currentVersion
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createServiceVersions = /* GraphQL */ `
  mutation CreateServiceVersions(
    $input: CreateServiceVersionsInput!
    $condition: ModelServiceVersionsConditionInput
  ) {
    createServiceVersions(input: $input, condition: $condition) {
      service
      version
      url
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateServiceVersions = /* GraphQL */ `
  mutation UpdateServiceVersions(
    $input: UpdateServiceVersionsInput!
    $condition: ModelServiceVersionsConditionInput
  ) {
    updateServiceVersions(input: $input, condition: $condition) {
      service
      version
      url
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteServiceVersions = /* GraphQL */ `
  mutation DeleteServiceVersions(
    $input: DeleteServiceVersionsInput!
    $condition: ModelServiceVersionsConditionInput
  ) {
    deleteServiceVersions(input: $input, condition: $condition) {
      service
      version
      url
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEC2Pricing = /* GraphQL */ `
  mutation CreateEC2Pricing(
    $input: CreateEC2PricingInput!
    $condition: ModelEC2PricingConditionInput
  ) {
    createEC2Pricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEC2Pricing = /* GraphQL */ `
  mutation UpdateEC2Pricing(
    $input: UpdateEC2PricingInput!
    $condition: ModelEC2PricingConditionInput
  ) {
    updateEC2Pricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEC2Pricing = /* GraphQL */ `
  mutation DeleteEC2Pricing(
    $input: DeleteEC2PricingInput!
    $condition: ModelEC2PricingConditionInput
  ) {
    deleteEC2Pricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDedicatedHostPricing = /* GraphQL */ `
  mutation CreateDedicatedHostPricing(
    $input: CreateDedicatedHostPricingInput!
    $condition: ModelDedicatedHostPricingConditionInput
  ) {
    createDedicatedHostPricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      throughput
      networkSpeed
      clockSpeed
      cost
      onDemandCost
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDedicatedHostPricing = /* GraphQL */ `
  mutation UpdateDedicatedHostPricing(
    $input: UpdateDedicatedHostPricingInput!
    $condition: ModelDedicatedHostPricingConditionInput
  ) {
    updateDedicatedHostPricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      throughput
      networkSpeed
      clockSpeed
      cost
      onDemandCost
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDedicatedHostPricing = /* GraphQL */ `
  mutation DeleteDedicatedHostPricing(
    $input: DeleteDedicatedHostPricingInput!
    $condition: ModelDedicatedHostPricingConditionInput
  ) {
    deleteDedicatedHostPricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      throughput
      networkSpeed
      clockSpeed
      cost
      onDemandCost
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReservedInstancePricing = /* GraphQL */ `
  mutation CreateReservedInstancePricing(
    $input: CreateReservedInstancePricingInput!
    $condition: ModelReservedInstancePricingConditionInput
  ) {
    createReservedInstancePricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      term
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReservedInstancePricing = /* GraphQL */ `
  mutation UpdateReservedInstancePricing(
    $input: UpdateReservedInstancePricingInput!
    $condition: ModelReservedInstancePricingConditionInput
  ) {
    updateReservedInstancePricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      term
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReservedInstancePricing = /* GraphQL */ `
  mutation DeleteReservedInstancePricing(
    $input: DeleteReservedInstancePricingInput!
    $condition: ModelReservedInstancePricingConditionInput
  ) {
    deleteReservedInstancePricing(input: $input, condition: $condition) {
      id
      instanceType
      region
      cores
      ram
      iops
      os
      databaseEdition
      databaseVersion
      throughput
      networkSpeed
      clockSpeed
      cost
      term
      upFrontCost
      hourlyCost
      unitOfCost
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEC2InstanceTypes = /* GraphQL */ `
  mutation CreateEC2InstanceTypes(
    $input: CreateEC2InstanceTypesInput!
    $condition: ModelEC2InstanceTypesConditionInput
  ) {
    createEC2InstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEC2InstanceTypes = /* GraphQL */ `
  mutation UpdateEC2InstanceTypes(
    $input: UpdateEC2InstanceTypesInput!
    $condition: ModelEC2InstanceTypesConditionInput
  ) {
    updateEC2InstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEC2InstanceTypes = /* GraphQL */ `
  mutation DeleteEC2InstanceTypes(
    $input: DeleteEC2InstanceTypesInput!
    $condition: ModelEC2InstanceTypesConditionInput
  ) {
    deleteEC2InstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRDSInstanceTypes = /* GraphQL */ `
  mutation CreateRDSInstanceTypes(
    $input: CreateRDSInstanceTypesInput!
    $condition: ModelRDSInstanceTypesConditionInput
  ) {
    createRDSInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRDSInstanceTypes = /* GraphQL */ `
  mutation UpdateRDSInstanceTypes(
    $input: UpdateRDSInstanceTypesInput!
    $condition: ModelRDSInstanceTypesConditionInput
  ) {
    updateRDSInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRDSInstanceTypes = /* GraphQL */ `
  mutation DeleteRDSInstanceTypes(
    $input: DeleteRDSInstanceTypesInput!
    $condition: ModelRDSInstanceTypesConditionInput
  ) {
    deleteRDSInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAzureInstanceTypes = /* GraphQL */ `
  mutation CreateAzureInstanceTypes(
    $input: CreateAzureInstanceTypesInput!
    $condition: ModelAzureInstanceTypesConditionInput
  ) {
    createAzureInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAzureInstanceTypes = /* GraphQL */ `
  mutation UpdateAzureInstanceTypes(
    $input: UpdateAzureInstanceTypesInput!
    $condition: ModelAzureInstanceTypesConditionInput
  ) {
    updateAzureInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAzureInstanceTypes = /* GraphQL */ `
  mutation DeleteAzureInstanceTypes(
    $input: DeleteAzureInstanceTypesInput!
    $condition: ModelAzureInstanceTypesConditionInput
  ) {
    deleteAzureInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGCPInstanceTypes = /* GraphQL */ `
  mutation CreateGCPInstanceTypes(
    $input: CreateGCPInstanceTypesInput!
    $condition: ModelGCPInstanceTypesConditionInput
  ) {
    createGCPInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGCPInstanceTypes = /* GraphQL */ `
  mutation UpdateGCPInstanceTypes(
    $input: UpdateGCPInstanceTypesInput!
    $condition: ModelGCPInstanceTypesConditionInput
  ) {
    updateGCPInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGCPInstanceTypes = /* GraphQL */ `
  mutation DeleteGCPInstanceTypes(
    $input: DeleteGCPInstanceTypesInput!
    $condition: ModelGCPInstanceTypesConditionInput
  ) {
    deleteGCPInstanceTypes(input: $input, condition: $condition) {
      instanceType
      currentGeneration
      vcpus
      ram
      peakNetworkMBps
      peakThroughputMBps
      peakIops
      baselineNetworkMBps
      baselineThroughputMBps
      baselineIops
      clockSpeed
      proccessorArchitecture
      processor
      avgHourlyCost
      gpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEC2PricingVersion = /* GraphQL */ `
  mutation CreateEC2PricingVersion(
    $input: CreateEC2PricingVersionInput!
    $condition: ModelEC2PricingVersionConditionInput
  ) {
    createEC2PricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEC2PricingVersion = /* GraphQL */ `
  mutation UpdateEC2PricingVersion(
    $input: UpdateEC2PricingVersionInput!
    $condition: ModelEC2PricingVersionConditionInput
  ) {
    updateEC2PricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEC2PricingVersion = /* GraphQL */ `
  mutation DeleteEC2PricingVersion(
    $input: DeleteEC2PricingVersionInput!
    $condition: ModelEC2PricingVersionConditionInput
  ) {
    deleteEC2PricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEC2SavingsPlanVersion = /* GraphQL */ `
  mutation CreateEC2SavingsPlanVersion(
    $input: CreateEC2SavingsPlanVersionInput!
    $condition: ModelEC2SavingsPlanVersionConditionInput
  ) {
    createEC2SavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEC2SavingsPlanVersion = /* GraphQL */ `
  mutation UpdateEC2SavingsPlanVersion(
    $input: UpdateEC2SavingsPlanVersionInput!
    $condition: ModelEC2SavingsPlanVersionConditionInput
  ) {
    updateEC2SavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEC2SavingsPlanVersion = /* GraphQL */ `
  mutation DeleteEC2SavingsPlanVersion(
    $input: DeleteEC2SavingsPlanVersionInput!
    $condition: ModelEC2SavingsPlanVersionConditionInput
  ) {
    deleteEC2SavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRDSSavingsPlanVersion = /* GraphQL */ `
  mutation CreateRDSSavingsPlanVersion(
    $input: CreateRDSSavingsPlanVersionInput!
    $condition: ModelRDSSavingsPlanVersionConditionInput
  ) {
    createRDSSavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRDSSavingsPlanVersion = /* GraphQL */ `
  mutation UpdateRDSSavingsPlanVersion(
    $input: UpdateRDSSavingsPlanVersionInput!
    $condition: ModelRDSSavingsPlanVersionConditionInput
  ) {
    updateRDSSavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRDSSavingsPlanVersion = /* GraphQL */ `
  mutation DeleteRDSSavingsPlanVersion(
    $input: DeleteRDSSavingsPlanVersionInput!
    $condition: ModelRDSSavingsPlanVersionConditionInput
  ) {
    deleteRDSSavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRDSPricingVersion = /* GraphQL */ `
  mutation CreateRDSPricingVersion(
    $input: CreateRDSPricingVersionInput!
    $condition: ModelRDSPricingVersionConditionInput
  ) {
    createRDSPricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRDSPricingVersion = /* GraphQL */ `
  mutation UpdateRDSPricingVersion(
    $input: UpdateRDSPricingVersionInput!
    $condition: ModelRDSPricingVersionConditionInput
  ) {
    updateRDSPricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRDSPricingVersion = /* GraphQL */ `
  mutation DeleteRDSPricingVersion(
    $input: DeleteRDSPricingVersionInput!
    $condition: ModelRDSPricingVersionConditionInput
  ) {
    deleteRDSPricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReservedInstancePricingVersion = /* GraphQL */ `
  mutation CreateReservedInstancePricingVersion(
    $input: CreateReservedInstancePricingVersionInput!
    $condition: ModelReservedInstancePricingVersionConditionInput
  ) {
    createReservedInstancePricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReservedInstancePricingVersion = /* GraphQL */ `
  mutation UpdateReservedInstancePricingVersion(
    $input: UpdateReservedInstancePricingVersionInput!
    $condition: ModelReservedInstancePricingVersionConditionInput
  ) {
    updateReservedInstancePricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReservedInstancePricingVersion = /* GraphQL */ `
  mutation DeleteReservedInstancePricingVersion(
    $input: DeleteReservedInstancePricingVersionInput!
    $condition: ModelReservedInstancePricingVersionConditionInput
  ) {
    deleteReservedInstancePricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDedicatedHostPricingVersion = /* GraphQL */ `
  mutation CreateDedicatedHostPricingVersion(
    $input: CreateDedicatedHostPricingVersionInput!
    $condition: ModelDedicatedHostPricingVersionConditionInput
  ) {
    createDedicatedHostPricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDedicatedHostPricingVersion = /* GraphQL */ `
  mutation UpdateDedicatedHostPricingVersion(
    $input: UpdateDedicatedHostPricingVersionInput!
    $condition: ModelDedicatedHostPricingVersionConditionInput
  ) {
    updateDedicatedHostPricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDedicatedHostPricingVersion = /* GraphQL */ `
  mutation DeleteDedicatedHostPricingVersion(
    $input: DeleteDedicatedHostPricingVersionInput!
    $condition: ModelDedicatedHostPricingVersionConditionInput
  ) {
    deleteDedicatedHostPricingVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSavingsPlanVersion = /* GraphQL */ `
  mutation CreateSavingsPlanVersion(
    $input: CreateSavingsPlanVersionInput!
    $condition: ModelSavingsPlanVersionConditionInput
  ) {
    createSavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSavingsPlanVersion = /* GraphQL */ `
  mutation UpdateSavingsPlanVersion(
    $input: UpdateSavingsPlanVersionInput!
    $condition: ModelSavingsPlanVersionConditionInput
  ) {
    updateSavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSavingsPlanVersion = /* GraphQL */ `
  mutation DeleteSavingsPlanVersion(
    $input: DeleteSavingsPlanVersionInput!
    $condition: ModelSavingsPlanVersionConditionInput
  ) {
    deleteSavingsPlanVersion(input: $input, condition: $condition) {
      id
      region
      version
      date
      s3Path
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCaptureToolPathMap = /* GraphQL */ `
  mutation CreateCaptureToolPathMap(
    $input: CreateCaptureToolPathMapInput!
    $condition: ModelCaptureToolPathMapConditionInput
  ) {
    createCaptureToolPathMap(input: $input, condition: $condition) {
      id
      jsonSchema
      Tool
      s3Path
      sheet
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCaptureToolPathMap = /* GraphQL */ `
  mutation UpdateCaptureToolPathMap(
    $input: UpdateCaptureToolPathMapInput!
    $condition: ModelCaptureToolPathMapConditionInput
  ) {
    updateCaptureToolPathMap(input: $input, condition: $condition) {
      id
      jsonSchema
      Tool
      s3Path
      sheet
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCaptureToolPathMap = /* GraphQL */ `
  mutation DeleteCaptureToolPathMap(
    $input: DeleteCaptureToolPathMapInput!
    $condition: ModelCaptureToolPathMapConditionInput
  ) {
    deleteCaptureToolPathMap(input: $input, condition: $condition) {
      id
      jsonSchema
      Tool
      s3Path
      sheet
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createToolToSheetMap = /* GraphQL */ `
  mutation CreateToolToSheetMap(
    $input: CreateToolToSheetMapInput!
    $condition: ModelToolToSheetMapConditionInput
  ) {
    createToolToSheetMap(input: $input, condition: $condition) {
      id
      Tool
      sheet
      jsonSchema
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateToolToSheetMap = /* GraphQL */ `
  mutation UpdateToolToSheetMap(
    $input: UpdateToolToSheetMapInput!
    $condition: ModelToolToSheetMapConditionInput
  ) {
    updateToolToSheetMap(input: $input, condition: $condition) {
      id
      Tool
      sheet
      jsonSchema
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteToolToSheetMap = /* GraphQL */ `
  mutation DeleteToolToSheetMap(
    $input: DeleteToolToSheetMapInput!
    $condition: ModelToolToSheetMapConditionInput
  ) {
    deleteToolToSheetMap(input: $input, condition: $condition) {
      id
      Tool
      sheet
      jsonSchema
      ogJsonSchema
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createChainOfCustody = /* GraphQL */ `
  mutation CreateChainOfCustody(
    $input: CreateChainOfCustodyInput!
    $condition: ModelChainOfCustodyConditionInput
  ) {
    createChainOfCustody(input: $input, condition: $condition) {
      id
      taskId
      fileName
      timestamp
      arn
      user
      wereChangesMade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateChainOfCustody = /* GraphQL */ `
  mutation UpdateChainOfCustody(
    $input: UpdateChainOfCustodyInput!
    $condition: ModelChainOfCustodyConditionInput
  ) {
    updateChainOfCustody(input: $input, condition: $condition) {
      id
      taskId
      fileName
      timestamp
      arn
      user
      wereChangesMade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteChainOfCustody = /* GraphQL */ `
  mutation DeleteChainOfCustody(
    $input: DeleteChainOfCustodyInput!
    $condition: ModelChainOfCustodyConditionInput
  ) {
    deleteChainOfCustody(input: $input, condition: $condition) {
      id
      taskId
      fileName
      timestamp
      arn
      user
      wereChangesMade
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOSLookup = /* GraphQL */ `
  mutation CreateOSLookup(
    $input: CreateOSLookupInput!
    $condition: ModelOSLookupConditionInput
  ) {
    createOSLookup(input: $input, condition: $condition) {
      OLAToolOSValue
      OSType
      OSEdition
      OSVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOSLookup = /* GraphQL */ `
  mutation UpdateOSLookup(
    $input: UpdateOSLookupInput!
    $condition: ModelOSLookupConditionInput
  ) {
    updateOSLookup(input: $input, condition: $condition) {
      OLAToolOSValue
      OSType
      OSEdition
      OSVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOSLookup = /* GraphQL */ `
  mutation DeleteOSLookup(
    $input: DeleteOSLookupInput!
    $condition: ModelOSLookupConditionInput
  ) {
    deleteOSLookup(input: $input, condition: $condition) {
      OLAToolOSValue
      OSType
      OSEdition
      OSVersion
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSQLVersionLookup = /* GraphQL */ `
  mutation CreateSQLVersionLookup(
    $input: CreateSQLVersionLookupInput!
    $condition: ModelSQLVersionLookupConditionInput
  ) {
    createSQLVersionLookup(input: $input, condition: $condition) {
      id
      SQLServerVersion
      SQLV
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSQLVersionLookup = /* GraphQL */ `
  mutation UpdateSQLVersionLookup(
    $input: UpdateSQLVersionLookupInput!
    $condition: ModelSQLVersionLookupConditionInput
  ) {
    updateSQLVersionLookup(input: $input, condition: $condition) {
      id
      SQLServerVersion
      SQLV
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSQLVersionLookup = /* GraphQL */ `
  mutation DeleteSQLVersionLookup(
    $input: DeleteSQLVersionLookupInput!
    $condition: ModelSQLVersionLookupConditionInput
  ) {
    deleteSQLVersionLookup(input: $input, condition: $condition) {
      id
      SQLServerVersion
      SQLV
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDHPlacementLookup = /* GraphQL */ `
  mutation CreateDHPlacementLookup(
    $input: CreateDHPlacementLookupInput!
    $condition: ModelDHPlacementLookupConditionInput
  ) {
    createDHPlacementLookup(input: $input, condition: $condition) {
      id
      vCpuCapacity
      RAMCapacity
      Heterogeneous
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDHPlacementLookup = /* GraphQL */ `
  mutation UpdateDHPlacementLookup(
    $input: UpdateDHPlacementLookupInput!
    $condition: ModelDHPlacementLookupConditionInput
  ) {
    updateDHPlacementLookup(input: $input, condition: $condition) {
      id
      vCpuCapacity
      RAMCapacity
      Heterogeneous
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDHPlacementLookup = /* GraphQL */ `
  mutation DeleteDHPlacementLookup(
    $input: DeleteDHPlacementLookupInput!
    $condition: ModelDHPlacementLookupConditionInput
  ) {
    deleteDHPlacementLookup(input: $input, condition: $condition) {
      id
      vCpuCapacity
      RAMCapacity
      Heterogeneous
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMicrosoftLicenseLookup = /* GraphQL */ `
  mutation CreateMicrosoftLicenseLookup(
    $input: CreateMicrosoftLicenseLookupInput!
    $condition: ModelMicrosoftLicenseLookupConditionInput
  ) {
    createMicrosoftLicenseLookup(input: $input, condition: $condition) {
      id
      EAorSCE
      Level
      Year1
      Year2
      Year3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMicrosoftLicenseLookup = /* GraphQL */ `
  mutation UpdateMicrosoftLicenseLookup(
    $input: UpdateMicrosoftLicenseLookupInput!
    $condition: ModelMicrosoftLicenseLookupConditionInput
  ) {
    updateMicrosoftLicenseLookup(input: $input, condition: $condition) {
      id
      EAorSCE
      Level
      Year1
      Year2
      Year3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMicrosoftLicenseLookup = /* GraphQL */ `
  mutation DeleteMicrosoftLicenseLookup(
    $input: DeleteMicrosoftLicenseLookupInput!
    $condition: ModelMicrosoftLicenseLookupConditionInput
  ) {
    deleteMicrosoftLicenseLookup(input: $input, condition: $condition) {
      id
      EAorSCE
      Level
      Year1
      Year2
      Year3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRegionCodeLookup = /* GraphQL */ `
  mutation CreateRegionCodeLookup(
    $input: CreateRegionCodeLookupInput!
    $condition: ModelRegionCodeLookupConditionInput
  ) {
    createRegionCodeLookup(input: $input, condition: $condition) {
      id
      Region
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRegionCodeLookup = /* GraphQL */ `
  mutation UpdateRegionCodeLookup(
    $input: UpdateRegionCodeLookupInput!
    $condition: ModelRegionCodeLookupConditionInput
  ) {
    updateRegionCodeLookup(input: $input, condition: $condition) {
      id
      Region
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRegionCodeLookup = /* GraphQL */ `
  mutation DeleteRegionCodeLookup(
    $input: DeleteRegionCodeLookupInput!
    $condition: ModelRegionCodeLookupConditionInput
  ) {
    deleteRegionCodeLookup(input: $input, condition: $condition) {
      id
      Region
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPricingModelLookup = /* GraphQL */ `
  mutation CreatePricingModelLookup(
    $input: CreatePricingModelLookupInput!
    $condition: ModelPricingModelLookupConditionInput
  ) {
    createPricingModelLookup(input: $input, condition: $condition) {
      id
      Value
      Model
      Short
      DeviceChoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePricingModelLookup = /* GraphQL */ `
  mutation UpdatePricingModelLookup(
    $input: UpdatePricingModelLookupInput!
    $condition: ModelPricingModelLookupConditionInput
  ) {
    updatePricingModelLookup(input: $input, condition: $condition) {
      id
      Value
      Model
      Short
      DeviceChoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePricingModelLookup = /* GraphQL */ `
  mutation DeletePricingModelLookup(
    $input: DeletePricingModelLookupInput!
    $condition: ModelPricingModelLookupConditionInput
  ) {
    deletePricingModelLookup(input: $input, condition: $condition) {
      id
      Value
      Model
      Short
      DeviceChoice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createHRGLookup = /* GraphQL */ `
  mutation CreateHRGLookup(
    $input: CreateHRGLookupInput!
    $condition: ModelHRGLookupConditionInput
  ) {
    createHRGLookup(input: $input, condition: $condition) {
      id
      HRGList
      WSVersion
      WSEdition
      LicType
      LicAssignment
      DefaultLicType
      AutoCreatedHRG
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateHRGLookup = /* GraphQL */ `
  mutation UpdateHRGLookup(
    $input: UpdateHRGLookupInput!
    $condition: ModelHRGLookupConditionInput
  ) {
    updateHRGLookup(input: $input, condition: $condition) {
      id
      HRGList
      WSVersion
      WSEdition
      LicType
      LicAssignment
      DefaultLicType
      AutoCreatedHRG
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteHRGLookup = /* GraphQL */ `
  mutation DeleteHRGLookup(
    $input: DeleteHRGLookupInput!
    $condition: ModelHRGLookupConditionInput
  ) {
    deleteHRGLookup(input: $input, condition: $condition) {
      id
      HRGList
      WSVersion
      WSEdition
      LicType
      LicAssignment
      DefaultLicType
      AutoCreatedHRG
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInstanceRatioLookup = /* GraphQL */ `
  mutation CreateInstanceRatioLookup(
    $input: CreateInstanceRatioLookupInput!
    $condition: ModelInstanceRatioLookupConditionInput
  ) {
    createInstanceRatioLookup(input: $input, condition: $condition) {
      id
      InstanceFamily
      Ratio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInstanceRatioLookup = /* GraphQL */ `
  mutation UpdateInstanceRatioLookup(
    $input: UpdateInstanceRatioLookupInput!
    $condition: ModelInstanceRatioLookupConditionInput
  ) {
    updateInstanceRatioLookup(input: $input, condition: $condition) {
      id
      InstanceFamily
      Ratio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInstanceRatioLookup = /* GraphQL */ `
  mutation DeleteInstanceRatioLookup(
    $input: DeleteInstanceRatioLookupInput!
    $condition: ModelInstanceRatioLookupConditionInput
  ) {
    deleteInstanceRatioLookup(input: $input, condition: $condition) {
      id
      InstanceFamily
      Ratio
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createMicrosoftProductLookup = /* GraphQL */ `
  mutation CreateMicrosoftProductLookup(
    $input: CreateMicrosoftProductLookupInput!
    $condition: ModelMicrosoftProductLookupConditionInput
  ) {
    createMicrosoftProductLookup(input: $input, condition: $condition) {
      id
      ProductName
      PricePerUnit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateMicrosoftProductLookup = /* GraphQL */ `
  mutation UpdateMicrosoftProductLookup(
    $input: UpdateMicrosoftProductLookupInput!
    $condition: ModelMicrosoftProductLookupConditionInput
  ) {
    updateMicrosoftProductLookup(input: $input, condition: $condition) {
      id
      ProductName
      PricePerUnit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteMicrosoftProductLookup = /* GraphQL */ `
  mutation DeleteMicrosoftProductLookup(
    $input: DeleteMicrosoftProductLookupInput!
    $condition: ModelMicrosoftProductLookupConditionInput
  ) {
    deleteMicrosoftProductLookup(input: $input, condition: $condition) {
      id
      ProductName
      PricePerUnit
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEnvLookup = /* GraphQL */ `
  mutation CreateEnvLookup(
    $input: CreateEnvLookupInput!
    $condition: ModelEnvLookupConditionInput
  ) {
    createEnvLookup(input: $input, condition: $condition) {
      Env
      EnvType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEnvLookup = /* GraphQL */ `
  mutation UpdateEnvLookup(
    $input: UpdateEnvLookupInput!
    $condition: ModelEnvLookupConditionInput
  ) {
    updateEnvLookup(input: $input, condition: $condition) {
      Env
      EnvType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEnvLookup = /* GraphQL */ `
  mutation DeleteEnvLookup(
    $input: DeleteEnvLookupInput!
    $condition: ModelEnvLookupConditionInput
  ) {
    deleteEnvLookup(input: $input, condition: $condition) {
      Env
      EnvType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVMCOnTapLookup = /* GraphQL */ `
  mutation CreateVMCOnTapLookup(
    $input: CreateVMCOnTapLookupInput!
    $condition: ModelVMCOnTapLookupConditionInput
  ) {
    createVMCOnTapLookup(input: $input, condition: $condition) {
      id
      IOPs
      throughputMBps
      storageMinGB
      storageMaxGB
      IOPsMax
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVMCOnTapLookup = /* GraphQL */ `
  mutation UpdateVMCOnTapLookup(
    $input: UpdateVMCOnTapLookupInput!
    $condition: ModelVMCOnTapLookupConditionInput
  ) {
    updateVMCOnTapLookup(input: $input, condition: $condition) {
      id
      IOPs
      throughputMBps
      storageMinGB
      storageMaxGB
      IOPsMax
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVMCOnTapLookup = /* GraphQL */ `
  mutation DeleteVMCOnTapLookup(
    $input: DeleteVMCOnTapLookupInput!
    $condition: ModelVMCOnTapLookupConditionInput
  ) {
    deleteVMCOnTapLookup(input: $input, condition: $condition) {
      id
      IOPs
      throughputMBps
      storageMinGB
      storageMaxGB
      IOPsMax
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVMCModelLookup = /* GraphQL */ `
  mutation CreateVMCModelLookup(
    $input: CreateVMCModelLookupInput!
    $condition: ModelVMCModelLookupConditionInput
  ) {
    createVMCModelLookup(input: $input, condition: $condition) {
      id
      Sizing
      Description
      Type
      Good
      Better
      Best
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVMCModelLookup = /* GraphQL */ `
  mutation UpdateVMCModelLookup(
    $input: UpdateVMCModelLookupInput!
    $condition: ModelVMCModelLookupConditionInput
  ) {
    updateVMCModelLookup(input: $input, condition: $condition) {
      id
      Sizing
      Description
      Type
      Good
      Better
      Best
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVMCModelLookup = /* GraphQL */ `
  mutation DeleteVMCModelLookup(
    $input: DeleteVMCModelLookupInput!
    $condition: ModelVMCModelLookupConditionInput
  ) {
    deleteVMCModelLookup(input: $input, condition: $condition) {
      id
      Sizing
      Description
      Type
      Good
      Better
      Best
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createT3Lookup = /* GraphQL */ `
  mutation CreateT3Lookup(
    $input: CreateT3LookupInput!
    $condition: ModelT3LookupConditionInput
  ) {
    createT3Lookup(input: $input, condition: $condition) {
      id
      instance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateT3Lookup = /* GraphQL */ `
  mutation UpdateT3Lookup(
    $input: UpdateT3LookupInput!
    $condition: ModelT3LookupConditionInput
  ) {
    updateT3Lookup(input: $input, condition: $condition) {
      id
      instance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteT3Lookup = /* GraphQL */ `
  mutation DeleteT3Lookup(
    $input: DeleteT3LookupInput!
    $condition: ModelT3LookupConditionInput
  ) {
    deleteT3Lookup(input: $input, condition: $condition) {
      id
      instance
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createSQLPricing = /* GraphQL */ `
  mutation CreateSQLPricing(
    $input: CreateSQLPricingInput!
    $condition: ModelSQLPricingConditionInput
  ) {
    createSQLPricing(input: $input, condition: $condition) {
      id
      PricePervCpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateSQLPricing = /* GraphQL */ `
  mutation UpdateSQLPricing(
    $input: UpdateSQLPricingInput!
    $condition: ModelSQLPricingConditionInput
  ) {
    updateSQLPricing(input: $input, condition: $condition) {
      id
      PricePervCpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteSQLPricing = /* GraphQL */ `
  mutation DeleteSQLPricing(
    $input: DeleteSQLPricingInput!
    $condition: ModelSQLPricingConditionInput
  ) {
    deleteSQLPricing(input: $input, condition: $condition) {
      id
      PricePervCpu
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createVMCClusterLookup = /* GraphQL */ `
  mutation CreateVMCClusterLookup(
    $input: CreateVMCClusterLookupInput!
    $condition: ModelVMCClusterLookupConditionInput
  ) {
    createVMCClusterLookup(input: $input, condition: $condition) {
      id
      Description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVMCClusterLookup = /* GraphQL */ `
  mutation UpdateVMCClusterLookup(
    $input: UpdateVMCClusterLookupInput!
    $condition: ModelVMCClusterLookupConditionInput
  ) {
    updateVMCClusterLookup(input: $input, condition: $condition) {
      id
      Description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVMCClusterLookup = /* GraphQL */ `
  mutation DeleteVMCClusterLookup(
    $input: DeleteVMCClusterLookupInput!
    $condition: ModelVMCClusterLookupConditionInput
  ) {
    deleteVMCClusterLookup(input: $input, condition: $condition) {
      id
      Description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkloadUtilLookup = /* GraphQL */ `
  mutation CreateWorkloadUtilLookup(
    $input: CreateWorkloadUtilLookupInput!
    $condition: ModelWorkloadUtilLookupConditionInput
  ) {
    createWorkloadUtilLookup(input: $input, condition: $condition) {
      Metric
      Workload
      AvgUtil
      PeakUtil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkloadUtilLookup = /* GraphQL */ `
  mutation UpdateWorkloadUtilLookup(
    $input: UpdateWorkloadUtilLookupInput!
    $condition: ModelWorkloadUtilLookupConditionInput
  ) {
    updateWorkloadUtilLookup(input: $input, condition: $condition) {
      Metric
      Workload
      AvgUtil
      PeakUtil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkloadUtilLookup = /* GraphQL */ `
  mutation DeleteWorkloadUtilLookup(
    $input: DeleteWorkloadUtilLookupInput!
    $condition: ModelWorkloadUtilLookupConditionInput
  ) {
    deleteWorkloadUtilLookup(input: $input, condition: $condition) {
      Metric
      Workload
      AvgUtil
      PeakUtil
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWeightAdjustmentLookup = /* GraphQL */ `
  mutation CreateWeightAdjustmentLookup(
    $input: CreateWeightAdjustmentLookupInput!
    $condition: ModelWeightAdjustmentLookupConditionInput
  ) {
    createWeightAdjustmentLookup(input: $input, condition: $condition) {
      Weight
      Adjustment
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWeightAdjustmentLookup = /* GraphQL */ `
  mutation UpdateWeightAdjustmentLookup(
    $input: UpdateWeightAdjustmentLookupInput!
    $condition: ModelWeightAdjustmentLookupConditionInput
  ) {
    updateWeightAdjustmentLookup(input: $input, condition: $condition) {
      Weight
      Adjustment
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWeightAdjustmentLookup = /* GraphQL */ `
  mutation DeleteWeightAdjustmentLookup(
    $input: DeleteWeightAdjustmentLookupInput!
    $condition: ModelWeightAdjustmentLookupConditionInput
  ) {
    deleteWeightAdjustmentLookup(input: $input, condition: $condition) {
      Weight
      Adjustment
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWastedLicenseLookup = /* GraphQL */ `
  mutation CreateWastedLicenseLookup(
    $input: CreateWastedLicenseLookupInput!
    $condition: ModelWastedLicenseLookupConditionInput
  ) {
    createWastedLicenseLookup(input: $input, condition: $condition) {
      Cores
      WastedLicenses
      AHUBLicenses
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWastedLicenseLookup = /* GraphQL */ `
  mutation UpdateWastedLicenseLookup(
    $input: UpdateWastedLicenseLookupInput!
    $condition: ModelWastedLicenseLookupConditionInput
  ) {
    updateWastedLicenseLookup(input: $input, condition: $condition) {
      Cores
      WastedLicenses
      AHUBLicenses
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWastedLicenseLookup = /* GraphQL */ `
  mutation DeleteWastedLicenseLookup(
    $input: DeleteWastedLicenseLookupInput!
    $condition: ModelWastedLicenseLookupConditionInput
  ) {
    deleteWastedLicenseLookup(input: $input, condition: $condition) {
      Cores
      WastedLicenses
      AHUBLicenses
      id
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEvolveAIPromptLookup = /* GraphQL */ `
  mutation CreateEvolveAIPromptLookup(
    $input: CreateEvolveAIPromptLookupInput!
    $condition: ModelEvolveAIPromptLookupConditionInput
  ) {
    createEvolveAIPromptLookup(input: $input, condition: $condition) {
      id
      Prompt
      Response
      command
      reasoning
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEvolveAIPromptLookup = /* GraphQL */ `
  mutation UpdateEvolveAIPromptLookup(
    $input: UpdateEvolveAIPromptLookupInput!
    $condition: ModelEvolveAIPromptLookupConditionInput
  ) {
    updateEvolveAIPromptLookup(input: $input, condition: $condition) {
      id
      Prompt
      Response
      command
      reasoning
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEvolveAIPromptLookup = /* GraphQL */ `
  mutation DeleteEvolveAIPromptLookup(
    $input: DeleteEvolveAIPromptLookupInput!
    $condition: ModelEvolveAIPromptLookupConditionInput
  ) {
    deleteEvolveAIPromptLookup(input: $input, condition: $condition) {
      id
      Prompt
      Response
      command
      reasoning
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEvolveAICache = /* GraphQL */ `
  mutation CreateEvolveAICache(
    $input: CreateEvolveAICacheInput!
    $condition: ModelEvolveAICacheConditionInput
  ) {
    createEvolveAICache(input: $input, condition: $condition) {
      id
      user_message
      Prompt
      Response
      command
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEvolveAICache = /* GraphQL */ `
  mutation UpdateEvolveAICache(
    $input: UpdateEvolveAICacheInput!
    $condition: ModelEvolveAICacheConditionInput
  ) {
    updateEvolveAICache(input: $input, condition: $condition) {
      id
      user_message
      Prompt
      Response
      command
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEvolveAICache = /* GraphQL */ `
  mutation DeleteEvolveAICache(
    $input: DeleteEvolveAICacheInput!
    $condition: ModelEvolveAICacheConditionInput
  ) {
    deleteEvolveAICache(input: $input, condition: $condition) {
      id
      user_message
      Prompt
      Response
      command
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRedshiftTables = /* GraphQL */ `
  mutation CreateRedshiftTables(
    $input: CreateRedshiftTablesInput!
    $condition: ModelRedshiftTablesConditionInput
  ) {
    createRedshiftTables(input: $input, condition: $condition) {
      id
      tableName
      tableSchema
      tablePurpose
      tableType
      tableDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRedshiftTables = /* GraphQL */ `
  mutation UpdateRedshiftTables(
    $input: UpdateRedshiftTablesInput!
    $condition: ModelRedshiftTablesConditionInput
  ) {
    updateRedshiftTables(input: $input, condition: $condition) {
      id
      tableName
      tableSchema
      tablePurpose
      tableType
      tableDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRedshiftTables = /* GraphQL */ `
  mutation DeleteRedshiftTables(
    $input: DeleteRedshiftTablesInput!
    $condition: ModelRedshiftTablesConditionInput
  ) {
    deleteRedshiftTables(input: $input, condition: $condition) {
      id
      tableName
      tableSchema
      tablePurpose
      tableType
      tableDescription
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAgentActionHistory = /* GraphQL */ `
  mutation CreateAgentActionHistory(
    $input: CreateAgentActionHistoryInput!
    $condition: ModelAgentActionHistoryConditionInput
  ) {
    createAgentActionHistory(input: $input, condition: $condition) {
      actions {
        agent
        action
        arg
        sectionID
        __typename
      }
      user
      session
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAgentActionHistory = /* GraphQL */ `
  mutation UpdateAgentActionHistory(
    $input: UpdateAgentActionHistoryInput!
    $condition: ModelAgentActionHistoryConditionInput
  ) {
    updateAgentActionHistory(input: $input, condition: $condition) {
      actions {
        agent
        action
        arg
        sectionID
        __typename
      }
      user
      session
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAgentActionHistory = /* GraphQL */ `
  mutation DeleteAgentActionHistory(
    $input: DeleteAgentActionHistoryInput!
    $condition: ModelAgentActionHistoryConditionInput
  ) {
    deleteAgentActionHistory(input: $input, condition: $condition) {
      actions {
        agent
        action
        arg
        sectionID
        __typename
      }
      user
      session
      messageID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAIMessageHistory = /* GraphQL */ `
  mutation CreateAIMessageHistory(
    $input: CreateAIMessageHistoryInput!
    $condition: ModelAIMessageHistoryConditionInput
  ) {
    createAIMessageHistory(input: $input, condition: $condition) {
      user
      sender
      timestamp
      messageID
      session
      content
      context {
        content
        type
        messageID
        sectionID
        uuid
        __typename
      }
      attachments {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAIMessageHistory = /* GraphQL */ `
  mutation UpdateAIMessageHistory(
    $input: UpdateAIMessageHistoryInput!
    $condition: ModelAIMessageHistoryConditionInput
  ) {
    updateAIMessageHistory(input: $input, condition: $condition) {
      user
      sender
      timestamp
      messageID
      session
      content
      context {
        content
        type
        messageID
        sectionID
        uuid
        __typename
      }
      attachments {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAIMessageHistory = /* GraphQL */ `
  mutation DeleteAIMessageHistory(
    $input: DeleteAIMessageHistoryInput!
    $condition: ModelAIMessageHistoryConditionInput
  ) {
    deleteAIMessageHistory(input: $input, condition: $condition) {
      user
      sender
      timestamp
      messageID
      session
      content
      context {
        content
        type
        messageID
        sectionID
        uuid
        __typename
      }
      attachments {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRedshiftLogs = /* GraphQL */ `
  mutation CreateRedshiftLogs(
    $input: CreateRedshiftLogsInput!
    $condition: ModelRedshiftLogsConditionInput
  ) {
    createRedshiftLogs(input: $input, condition: $condition) {
      sessionMessageID
      user
      queryID
      userMessage
      type
      status
      sqlQuery
      results
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRedshiftLogs = /* GraphQL */ `
  mutation UpdateRedshiftLogs(
    $input: UpdateRedshiftLogsInput!
    $condition: ModelRedshiftLogsConditionInput
  ) {
    updateRedshiftLogs(input: $input, condition: $condition) {
      sessionMessageID
      user
      queryID
      userMessage
      type
      status
      sqlQuery
      results
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRedshiftLogs = /* GraphQL */ `
  mutation DeleteRedshiftLogs(
    $input: DeleteRedshiftLogsInput!
    $condition: ModelRedshiftLogsConditionInput
  ) {
    deleteRedshiftLogs(input: $input, condition: $condition) {
      sessionMessageID
      user
      queryID
      userMessage
      type
      status
      sqlQuery
      results
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createNewFaq = /* GraphQL */ `
  mutation CreateNewFaq($input: KendraFaqInput!) {
    createNewFaq(input: $input) {
      response
      __typename
    }
  }
`;
export const editExistingFaq = /* GraphQL */ `
  mutation EditExistingFaq($input: changeKendraFaqInput!) {
    editExistingFaq(input: $input) {
      response
      __typename
    }
  }
`;
export const removeExistinFaq = /* GraphQL */ `
  mutation RemoveExistinFaq($input: changeKendraFaqInput!) {
    removeExistinFaq(input: $input) {
      response
      __typename
    }
  }
`;
export const CreateTopicForChatHistory = /* GraphQL */ `
  mutation CreateTopicForChatHistory($input: CreateTopicForChatHistoryInput!) {
    CreateTopicForChatHistory(input: $input) {
      id
      session
      topic
      emoji
      user
      __typename
    }
  }
`;
export const deployCloudFormation = /* GraphQL */ `
  mutation DeployCloudFormation($input: DeployCloudFormationInput!) {
    deployCloudFormation(input: $input) {
      response
      __typename
    }
  }
`;
