
// FaqItem.jsx
import React, { useState } from 'react';
import { Icon } from '@mdi/react';
import { mdiDotsVertical, mdiPencil, mdiDelete, mdiCloudQuestion } from '@mdi/js';
import HighlightedText from './HighlightText';

const FaqItem = ({ item, onEdit, onDelete }) => {
  // State to control showing controls 
  const [showControls, setShowControls] = useState(false);

  // Trigger opening the edit modal form
  const handleEdit = () => {
    onEdit(item);
    setShowControls(false); // Hide controls after action
  };

  // Trigger deletion confirmation and process
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this FAQ?')) {
      onDelete(item.id);
    }
  };

  return (
    // Overall FAQ item container
    <div className="faq-item">
      {/* Question */}
      <h3 className="question"><Icon className='qcon' path={mdiCloudQuestion} size={1} />{item.AdditionalAttributes.find(attr => attr.Key === 'QuestionText').Value.TextWithHighlightsValue.Text}</h3>
                           

      {/* Answer */}
      <div className="answer">
            <HighlightedText
                text={item.AdditionalAttributes.find(attr => attr.Key === 'AnswerText').Value.TextWithHighlightsValue.Text}
                highlights={item.AdditionalAttributes.find(attr => attr.Key === 'AnswerText').Value.TextWithHighlightsValue.Highlights}
            />
        </div>
      {/* Icon trigger to show controls */}
      <Icon 
        path={mdiDotsVertical} 
        size={1} 
        className="faq-item-ellipsis"
        onClick={() => setShowControls(!showControls)}  
      />

      {/* Show controls if triggered */}
      {showControls && (
        <div className="faq-item-controls">
          <button
            className="faq-item-control-btn"
            onClick={handleEdit}  
          >
            <Icon path={mdiPencil} size={1} className="icon-btn"/>
          </button>

         <button
           className="faq-item-control-btn"  
           onClick={handleDelete}
         >
           <Icon path={mdiDelete} size={1} className="icon-btn"/>
         </button>
       </div>
      )}
    </div>
  );
};

export default FaqItem;