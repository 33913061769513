
import React, { useState, useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import '../styles/Terminal.css';


const Terminal = ({ message }) => {

    // State to store the calculated terminalWidthChars in characters
    const [terminalWidthChars, setTerminalWidthChars] = useState(92); // A default terminalWidthChars
    const terminalRef = useRef(null);

    useEffect(() => {
        if (terminalRef.current) {
            const charWidth = 2; // The terminalWidthChars of a '-' character in pixels, adjust as needed
            const computedStyle = window.getComputedStyle(terminalRef.current);
            const padding = parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
            const actualWidth = terminalRef.current.clientWidth - padding; // Width without padding
            const numChars = Math.floor(actualWidth / charWidth);
            setTerminalWidthChars(numChars); // Set the number of characters that can fit into the terminalWidthChars
        } 
    }, [terminalRef]);

    // Function to prettify and center the lines
    const prettifyAndCenterLine = (line) => {
        const wrappedLines = line.length > terminalWidthChars ? line.match(new RegExp(`.{1,${terminalWidthChars}}`, 'g')) : [line];
        const padding = (terminalWidthChars - line.length) / 2;
        return wrappedLines.map(l => l.padStart(l.length +  padding, ' ').padEnd(padding, ' '));
    };

    const renderTerminalLines = (textContent) => {
        return (
            <div className="terminal-content">
                {DOMPurify.sanitize(textContent).split('\n').map((line, index) => (
                <div key={index} className="terminal-line">
                    {prettifyAndCenterLine(line)}
            </div>
          ))}
        </div>
      );
    };


    return (
        <div className="terminal-container">
            <div className="terminal-header">
                <div className="terminal-buttons">
                <span className="terminal-button terminal-button-exit" />
                <span className="terminal-button terminal-button-minimize" />
                <span className="terminal-button terminal-button-expand" />
                </div>
                <div className="terminal-title">Evolve Shell</div>
            </div>
                {renderTerminalLines(message)}
            </div>

        );
    };

export default Terminal;