
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import '../styles/needInfo.css';
import { mdiFormatAlignBottom } from '@mdi/js';
import Icon from '@mdi/react';

// Components will be based on the backend schema
const Dropdown = ({ label, description, enums, onChange }) => (
  <div className="dropdown-container">
    {label && <label className="dropdown-label">{label}</label>}
    {description && <p className="dropdown-description">{description}</p>}
    <select className="dropdown-select" onChange={onChange}> 
    {enums.map((enumValue, idx) => (
      <option key={idx} value={enumValue}>{enumValue}</option>
    ))}
    </select>
  </div>
);


const Radio = ({ label, value, checked, onChange }) => (
    <label className={checked ? "radio-label checked" : "radio-label"}>
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        className="radio-input"
      />
      {label}
    </label>
  );
  // Updated Radio propTypes
Radio.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};
  
  // RadioGroup component with state management for the checked state
const RadioGroup = ({ label, description, enums, selectedValue, onChange }) => (
    <fieldset className="radio-group">
      {label && <label className="radio-group-label">{label}</label>}
      {description && <p className="radio-group-description">{description}</p>}
      {enums.map((enumValue, idx) => (
        <Radio
          key={idx}
          label={enumValue}
          value={enumValue}
          checked={selectedValue === enumValue}
          onChange={onChange}
        />
      ))}
    </fieldset>
);

// Updated RadioGroup propTypes
RadioGroup.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    enums: PropTypes.array.isRequired,
    selectedValue: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  

const TextInput = ({ label, placeholder, onChange }) => (
  <div className="text-input-container">
    {label && <label className="text-input-label">{label}</label>}
    <input type="text" placeholder={placeholder} className="text-input" onChange={onChange} /> 
  </div>
);

// Add propTypes for the new onChange prop
Dropdown.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    enums: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired, // Add this line
};


TextInput.propTypes = {
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired, // Add this line
  };


const NeedInfo = ({ content, onSubmit, formData, setFormData }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const handleRadioChange = (event, groupName) => {
        console.log("event: ", event)
        console.log("fieldName: ", groupName)
        setFormData({
            ...formData,
            fields: {
                ...formData.fields,
                [groupName]: event.target.value
            }
        });
        console.log("form data: ", formData)
    };
    const handleInputChange = (event, fieldName) => {
        console.log("event: ", event)
        console.log("fieldName: ", fieldName)
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        setFormData({
            ...formData,
            fields: {
                ...formData.fields,
                [fieldName]: value
            }
        });
        console.log("form data: ", formData)
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted data:', formData);
        onSubmit(formData); // Trigger the submit event
        setIsSubmitted(true);
        // Optional: Reset the form or redirect the user after the animation
        setTimeout(() => {
            setFormData({
                fields: {},
                addAdditionalInfo: false
            });
            // If you want to navigate the user to a different page, do so here
        }, 4000); // Adjust the timeout duration to match your animation timing
    };


    return isSubmitted ? (
        <div className="submitted-message">
                Your response has been submitted
                <Icon path={mdiFormatAlignBottom} size={1} style={{marginLeft: '10px'}}/> 
        </div>
      ) : (
            <form className="need-info-container" onSubmit={handleSubmit}>
            <div className="input-container">
                {content?.dropdowns?.map((dropdown, idx) => (
                <Dropdown key={idx} {...dropdown} onChange={event => handleInputChange(event, dropdown.label)} />
                ))}
                {content?.radio_buttons?.map((radioButtonGroup, idx) => (
                    <RadioGroup
                        key={idx}
                        label={radioButtonGroup.label}
                        description={radioButtonGroup.description}
                        enums={radioButtonGroup.enums}
                        selectedValue={formData.fields[radioButtonGroup.label]}
                        onChange={(event) => handleRadioChange(event, radioButtonGroup.label)}
                    />
                ))}

                {content?.text_forms?.map((textInput, idx) => (
                <TextInput key={idx} {...textInput} onChange={event => handleInputChange(event, textInput.label)} />
                ))}
                </div>
                {content?.why_needed && (
                    <div className="explain-container">
                        <div className="explain-header">Why is this information needed?</div>
                        <div className="explain-content">{content.why_needed}</div>
                    </div>
                )}
                <div className="submit-container">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={formData.addAdditionalInfo}
                            onChange={event => setFormData({ ...formData, addAdditionalInfo: event.target.checked })}
                            className="checkbox-input"
                        />
                        Add additional info
                    </label>
                    <button type="submit" className="submit-button">Submit</button>
                </div>
            </form>
        );
    };

NeedInfo.propTypes = {
    content: PropTypes.shape({
      dropdowns: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        enums: PropTypes.array.isRequired,
        description: PropTypes.string,
      })),
      radio_buttons: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        enums: PropTypes.array.isRequired,
        description: PropTypes.string,
      })),
      text_forms: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
      })),
    }).isRequired,
  };
  
export default NeedInfo;