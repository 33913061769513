import React, { useEffect, useRef, useState } from 'react';

import '../styles/ChatMessages.css';
import MarkdownToJsx from 'markdown-to-jsx';
import { languageIcons } from '../data/languageIcons';
import { mdiContentCopy } from '@mdi/js';
import { Icon } from '@mdi/react';
import hljs from 'highlight.js';
import DeployYaml from './DeployYaml';
import Terminal from './Terminal';
import NeedInfo from './needInfo';
const ChatSections = ({ sections, handleNeedInfoSubmit, formData, setFormData }) => {
    const messagesEndRef = useRef(null);
    const sectionContainerRef = useRef(null);
    const [marker, setMarker] = useState({ visible: false, top: 0, left: 0 });
    useEffect(() => {
        const containerRect = sectionContainerRef.current.getBoundingClientRect();
        const containerRightBound = containerRect.right;
    
        const lastSection = sections[sections.length - 1];
        if (lastSection && (lastSection.type === 'stream' || lastSection.type === 'code')) {
            // Instead of lastChild, we look for the last text node
            const lastMessageElement = sectionContainerRef.current.lastElementChild;
            let textNodes = [...lastMessageElement.childNodes].filter(node => node.nodeType === Node.TEXT_NODE);
            let lastTextNode = textNodes[textNodes.length - 1];
    
            if (lastTextNode) {
                let range = document.createRange();
                let lastCharIndex = lastTextNode.length - 1;
                
                range.setStart(lastTextNode, lastCharIndex);
                range.setEnd(lastTextNode, lastCharIndex + 1);
                let rect = range.getBoundingClientRect();

                // Calculate the x-coordinate for the marker, add padding if necessary
                let markerLeft = rect.right - containerRect.left + 10; // 10px to the right of the last char
                let markerTop = rect.top - containerRect.top; // Align with the top of the last char

                // Check if the marker goes beyond the container bounds
                if ((rect.right + 10) >= containerRightBound) {
                    // Move marker to the beginning of the next line
                    markerLeft = 0; // Reset to the start of the next line
                    markerTop += rect.height; // Move down by the height of one line
                }

                setMarker({
                    visible: true,
                    top: markerTop,
                    left: markerLeft
                });
            }
        } else {
            setMarker(prevMarker => ({ ...prevMarker, visible: false }));
        }
    }, [sections]);

    // Function to handle copying code to clipboard
    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            console.log("Copied to clipboard successfully!");
        }, (err) => {
            console.error('Error copying to clipboard: ', err);
        });
    };

    const formatCodeBlock = (codeContent, language) => {
        const languageIcon = languageIcons[language] || language;
        // Fixed the misspelling from 'chatAt' to 'charAt', and 'str' is not needed
        let formattedCodeContent = codeContent + "\n";
        return (
            <div className="code-container">
                <div className="code-ribbon">
                    <Icon path={languageIcon} size={1} className="language-icon" />
                    <button className="copy-button" onClick={() => copyToClipboard(formattedCodeContent)}>
                        <Icon path={mdiContentCopy} size={0.7} />
                    </button>
                </div>
                <pre><code className={`language-${language} hljs`}>{hljs.highlightAll(formattedCodeContent)}</code></pre>
            </div>
        );
    };
    
      const formatMessageStream = (msg) => {
        const parts = [];

        // Use a regex to split based on code blocks but maintain the delimiter
        const splitContent = msg.content.split(/(```\w*\s[\s\S]*?)(```)?/);
    
        splitContent.forEach(part => {
            // Ensure 'part' is not undefined
            if (!part) {
                return;
            }

            // Match the code block with a potential absent closing fence
            const codeBlockMatch = part.match(/```(\w+)\s([\s\S]*?)(```)?/);
            if (codeBlockMatch && codeBlockMatch[1] !== '') {
                const language = codeBlockMatch[1];
                let codeContent = codeBlockMatch[2];

                    
                // Proceed to format the code block including the appended fence if necessary
                parts.push(formatCodeBlock(codeContent, language));
            } else {
                // Treat as plain text or markdown
                parts.push(<MarkdownToJsx>{part}</MarkdownToJsx>);
            }
        });
    
        return parts;
    }; 

    const formatMessage = (msg) => {
        // Simply return the appropriate component based on msg.type
        if (msg.type === 'needinfo') {
            return <NeedInfo content={msg.content} onSubmit={handleNeedInfoSubmit} formData={formData} setFormData={setFormData} />;
        } else if (msg.type === 'user') {
            return msg.content;
        } else if (msg.type === 'submittedinfo') {
            return <MarkdownToJsx>{msg.content}</MarkdownToJsx>;
        } else if (msg.type === 'stream') {
            return formatMessageStream(msg);
        } else if (msg.type === 'markdown') {
            return <MarkdownToJsx>{msg.content}</MarkdownToJsx>;
        } else if (msg.type === 'terminal') {
            return <Terminal message={msg.content} />;
        } else if (msg.type === 'deployment') {
            return formatMessageStream(msg);
        } else if (msg.type === 'cloudformation') {
            return <DeployYaml msgObj={msg} />;
        } else {
            return <span>{msg.content}</span>;
        }
    };

    return (
        <div className="chat-sections" ref={sectionContainerRef}>
            {sections.map((section, index) => (
                <div key={section.sectionID || index} className="message-section">
                    {formatMessage(section)}
                </div>
            ))}
            {marker.visible && (
                <div
                    className="stream-marker"
                    style={{
                        position: 'absolute',
                        top: marker.top + 'px',
                        left: marker.left + 'px',
                        borderRadius: '50%',
                        backgroundColor: 'white',
                        width: '10px',
                        height: '10px'
                    }}
                />
            )}
            <div ref={messagesEndRef} />
        </div >
    );
};

export default ChatSections;