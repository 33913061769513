import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import App from './AppTest';
import { UserGroupProvider } from './contexts/UserGroup';
import * as serviceWorker from './ServiceWorker';
const root  = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <UserGroupProvider>
      <App />
    </UserGroupProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
