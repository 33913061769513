import React, { useState, useEffect } from 'react';
import '../styles/Deployment.css';
import * as mutations from '../graphql/mutations';
import { API, graphqlOperation, Storage } from 'aws-amplify'; 

const DeployYaml = ({ msgObj }) => {
  const content = JSON.parse(msgObj.content);
  const [pngImage, setPngImage] = useState(null);

  const [deploymentInput, setDeploymentInput] = useState({
    templatePath: content.templatePath || 'default_template_path.yaml',
    stackName: content.stackName || 'default-stack-name',
    // Convert the initial parameters to an array of objects
    parameters: content.parameters.map(param => ({
      ParameterKey: param.ParameterKey,
      ParameterValue: param.DefaultValue || ''
    })),
    // Assuming capabilities is an array of strings in content
    capabilities: content.capabilities || [],
    // Convert initial tags to an array of objects
    tags: content.tags.map(tag => ({
      Key: tag.Key,
      Value: tag.Value
    })),
    enableRollback: content.enableRollback !== undefined ? content.enableRollback : true,
  });

  useEffect(() => {
    const graphKey = content.graph;
    console.log("Graph key:", graphKey);
    if (graphKey) {
      const getGraphImage = async () => {
        try {
          const imageUrl = await Storage.get(graphKey, { level: 'private' });
          console.log('Retrieved PNG image:', imageUrl);
          setPngImage(imageUrl);
        } catch (error) {
          console.error('Error retrieving PNG image:', error);
        }
      };

      getGraphImage();
    }
  }, [content]); // Dependency on content.graph

  const handleInputChange = (key, value) => {
    // Update the parameters array in the deploymentInput state when input changes
    setDeploymentInput(prevInput => ({
      ...prevInput,
      parameters: prevInput.parameters.map(p =>
        p.ParameterKey === key ? { ...p, ParameterValue: value } : p
      ),
      stackName: key === 'stackName' ? value : prevInput.stackName
    }));
  };

  const handleDeploy = async () => {
    // Pass parameters and tags directly since they're now in the correct format
    const gqlInput = {
      ...deploymentInput,
      parameters: deploymentInput.parameters,
      tags: deploymentInput.tags,
    };
    console.log("Deploying CloudFormation with input:", gqlInput);

    try {
      const response = await API.graphql(graphqlOperation(mutations.deployCloudFormation, { input: gqlInput }));
      console.log("Deployment initiated with response: ", response);
    } catch (error) {
      console.error('Error deploying CloudFormation:', error);
    }
  };
  return (
    <div className="deployment-container">
      <div className="parameters-container">
        <h3 className="deployment-header">
          <span className="header-text">✒️ Parameters</span>
        </h3>
        <div className="parameters">
          <label className="parameter-label">Stack Name</label>
          <input
            className="parameter-input"
            type="text"
            value={deploymentInput.stackName}
            onChange={(e) => handleInputChange('stackName', e.target.value)}
            placeholder={"Enter stack name"}
          />
          {deploymentInput.parameters.map((param, idx) => (
            <div key={idx} >
              <label className="parameter-label" title={param.Description || ''}>{param.ParameterKey}</label>
              <input
                className="parameter-input"
                type="text"
                value={param.ParameterValue}
                onChange={(e) => handleInputChange(param.ParameterKey, e.target.value)}
                placeholder={param.ParameterValue || 'Enter value'}
              />
            </div>
          ))}
        </div>
        <button className="deploy-button" onClick={handleDeploy}>
            Deploy
        </button>
      </div>
      <div className="resources-container">
        <h3 className="deployment-header">
          <span className="header-text">☁️ Map</span>
        </h3>
        <div className="png-container">
        {pngImage && <img src={pngImage} alt="Cloud Map" />}
        </div>
      </div>
    </div>
  );
};

export default DeployYaml;