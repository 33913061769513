import React from 'react';
import '../styles/MainContent.css'

const MainContent = ({ children }) => {

    return (
        <div className="main-content">
            {children}
        </div>
    );
};

export default MainContent;
